.tickbar-btn {
  padding: 5px 12px 5px 12px;
  border: 1px solid #c9cccf;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.tickbar-btn-selected {
  background-color: #07a9d2;
  color: white;
}
