.setting-opt-row {
  padding: 5px 6px;
  display: flex;
  align-items: center;
  margin-left: -5px;
}
.setting-opt-row:active {
  background-color: rgb(243, 243, 248);
}
.option-name {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  padding-top: 10px;
  color: #4c646a;
}

.setting-dahs-primary-active-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 169px;
  height: 40px;
  background: #0087A9;
  box-shadow: 0px 4px 20px #0087A9 (77, 65, 132, 0.2);
  border-radius: 24px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.setting-dash-primary-inactive-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 178px;
  height: 40px;
  border: 1px solid #999EA4;
  border-radius: 24px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: white;
  display: flex;
  align-items: center;
  text-align: center;

  color: #07A9D2;
}

.setting-dash-primary-inactive-btn:hover {
  background: #E8FAFF !important;
  color: #0087A9 !important;
  border: 1px solid #0087A9 !important;
}

.setting-dash-btn-text {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
}

.dashboard-footer-btn-container {
  margin-top: 25px;
  position: sticky;
}

.setting-modal-inside-main-content-body {
  overflow-y: auto;
}
