.seadch-term-card-container {


    background: #FFFFFF;
    border: 1px solid #E1DFEB;
    /* Drop Shadow_12 */

    box-shadow: 0px -2px 4px rgba(202, 190, 255, 0.12);
    border-radius: 8px;


}

.seadch-term-card-container .search-term-card-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 20px;
    margin-top: 20px;

}

.search-term-table .search-term-main-table table {
    width: 100%;
    overflow-x: auto;

}

.seadch-term-card-container .search-term-card-header .et-card-heading {
    display: flex;
    flex-direction: row;
}

.seadch-term-card-container .search-term-card-header .et-card-heading .setting-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    height: 40px;
    padding-left: 10px;
    background: #047857;
    opacity: 0.7;
    box-shadow: 0px 4px 20px rgba(110, 231, 183, 0.1);
    border-radius: 8px;
    text-align: center;
    color: #FFFFFF;
}

.edit-target-tabledata-td {
    justify-content: center;
    align-items: center;
    padding: 12px 7px;
    height: 51.9px;
    border-radius: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.main-edit-targetting-table-body-container thead {
    border-radius: 8px;

    border-radius: 8px 8px 0px 0px;
    width: 100%;
    color: white;
    background-color: #FF8364;
    border-right: 1px solid #FF8364;
    border-left: 1px solid #FF8364;
    ;
}



.spends-icon {
    vertical-align: baseline;
    color: #4D4184;
    cursor: pointer;
}

.spends-icon-activated {
    filter: invert(25%) sepia(74%) saturate(601%) hue-rotate(212deg) brightness(40%) contrast(85%);
}

.info-icon {
    vertical-align: center;
}

.search-term-main-table .search-term-main-table table {
    width: 100%;

}

.search-term-table .search-term-main-table table thead {
    position: sticky;
    top: 0;

}

.search-term-table .search-term-main-table table thead tr th {
    text-align: start;
    border-right: 1px solid white;
    padding: 8px 20px;

}

.search-term-table .search-term-main-table table tbody tr td {
    padding: 8px 20px;
    border-right: 1px solid #CCCCCC;

}

.search-term-table .search-term-main-table {
    /* height: 591px; */
    height: 79vh;
    width: 100%;
    overflow-x: auto;
}

.search-term-table .search-term-main-table .switch-btn-container {
    display: flex;
    justify-content: center;
}

.search-term-main-table table thead tr th:nth-child(1) {
    /* background-color: #047857; */
    width: 256.38px;
}


.search-term-main-table table tbody tr td:nth-child(2) {
    width: 374.96px;
    text-align: left;

}

/* .search-term-main-table .edit-second-column {
    position: sticky;
    left: 135px;
    background-color: white;
    padding: 12px 15px;
    height: auto;
    box-shadow: 3px 0 5px -2px #888;
    z-index: 1;
} */

.seadch-term-card-container .search-term-card-header .search-input-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search-term-table .search-term-main-table table tbody tr .st-text-left-align,
.search-term-table .search-term-main-table table thead tr .st-text-left-align
{
    text-align: start;

}

.search-term-table .search-term-main-table table tbody tr .st-end-align, 
.search-term-table .search-term-main-table table thead tr .st-end-align {
    text-align: end;

}
.st-heads-with-two-words{
    width: 136px;
}
@media only screen and (max-width: 820px) {

    .seadch-term-card-container .search-term-card-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

    }

    .seadch-term-card-container .search-term-card-header .search-input-container {
        display: flex;
        justify-content: start;
        align-items: center;
    }



}