.custom-drop-down-footer-btn {
    gap: 8px;
    width: 72px;
    height: 28px;
}
.custom-drop-down-saerch-box-footer {
    height: 50px;
    background-color: red;
    /* width: 302px; */
    height: 48px;
    position: absolute;
    /* top: 253px; */
    /* bottom: -80px; */
    /* margin-bottom: 10px; */
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 0px 50px; */
}

.cd-select-option-container {
    position: absolute;
    width: 180px;
    /* min-height: auto;
    max-height: 202px; */
    /* height: 202px; */
    z-index: 200;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    padding: 2px 2px 0px 0px;
    /* overflow: scroll; */
    /* overflow-y: scroll; */
}

.options-contaoner-cd {
    overflow-y: auto;
    height: 200px;
    min-height: 160px;
    max-height: 270px;
    position: relative;
    margin-bottom: 50px;
    overflow-x: hidden;
}

.slect-name-text {
    /* padding-left: 15px; */
    font-size: 12px;
    color: #63677B;
    color: #63677B;
}

.dd-opt-label {
    font-size: 12px;
    color: #63677B;
}

.selected-option-count-text {
    color: #4D4184;
    font-size: 12px;
}

.minus-icon-container-width-checked--count {
    padding-left: 10px;
}

.count-of-selcted-in-main-button-container {
    /* width: 24px; content was hiding because of this particular width */
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    background: #07A9D2;
    border-radius: 35px;
    margin-left: 8px;
    color: white;
}