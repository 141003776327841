.setting-modal {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.content {
  padding: 10px;
  width: 430px;
  padding: 10px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  /* overflow-y: hidden; */
}

.backdrop {
  background-color: black;
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  margin: 0px;
}

.dialogClassName {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  right: 0px;
  position: absolute;
  margin: 0px;
  transition: right 4s;
  transition-timing-function: ease-in-out;
}

.dash-layout-close-icon-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 10px;
  /* /* padding-left: 40px; */
  padding-top: 10px;
  width: 100%;
  cursor: pointer;
}

.dashboard-layout-settings-head {
  /* padding-right: 20px; */
  padding-bottom: 20px;
   padding-left: 5px; 
}
.dash-set-modal-body {
  padding-left: 5px;
}
.dashboard-setting-modal-container .modal {
  padding: 0px;
}

.dashboard-layout-setting-content-container {
  padding-bottom: 20px;
  padding-top: 20px;
}
