.error-forget-password {
    box-sizing: border-box;
    width: 495px;
    height: 45px;
    outline: none;
    color: #d72c0d;
}

/* MS-115 */
.forget-password-popup .modal-header
{
    padding: 15px 15px 0 15px ;
}
.forget-password-popup .modal-header .btn-close
{
    margin: 0;
    top:0;
}
.forget-password-popup .modal-dialog.modal-lg
{
    max-width: 550px;
}
.forget-password-popup .modal-footer
{
    box-shadow: rgba(0, 0, 0, 0.08) 0px -4px 4px;
}
.forget-password-popup .btn.btn-primary
{
    background-color: rgb(77, 65, 132);
    color: rgb(255, 255, 255);
    border-radius: 24px;
    padding: 6px 12px;
    height: 40px;
}