.edit-ads-targetting-card-container {
    display: flex;
    flex-direction: column;
    padding-top: 3px;

    display: flex;
    /* padding: 10px 20px; */
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #CCE1E6;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
    border-radius: 8px;


    /* background-color: #4D4184; */

}

/* .edit-targetting-table-container {
    margin-top: 40px;
} */

.edit-ads-targetting-card-container .edit-targetting-card-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: #047857; */
    /* margin-bottom: 20px; */
    margin-top: 20px;
    padding: 0px 20px;

}

.edit-ads-targetting-card-container .edit-targetting-card-header .et-card-heading {
    display: flex;
    flex-direction: row;
}

.edit-ads-targetting-card-container .edit-targetting-card-header .et-card-heading .setting-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    height: 40px;
    padding-left: 10px;
    background: #047857;
    opacity: 0.7;
    box-shadow: 0px 4px 20px rgba(110, 231, 183, 0.1);
    border-radius: 8px;
    text-align: center;
    color: #FFFFFF;
}

.edit-target-tabledata-td {
    justify-content: center;
    align-items: center;
    padding: 12px 7px;
    height: 51.9px;
    border-radius: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.main-edit-targetting-table-body-container thead {
    border-radius: 8px;

    border-radius: 8px 8px 0px 0px;
    width: 100%;
    color: white;
    background-color: #FF8364;
    border-right: 1px solid #FF8364;
    border-left: 1px solid #FF8364;
    ;
}



.spends-icon {
    vertical-align: baseline;
    display: flex;
    align-items: center;
    padding-left: 6px;
}

.current-bid-td-container {
    width: 144px;
    height: 32px;
    background: #E8FAFF;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 4px;

}

.current-bid-td-container input {
    width: 112px;
    height: 32px;
    background: #E8FAFF;
    border: 1px solid rgba(77, 65, 132, 0.2);
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #4F5366;
    padding-left: 5px;
    border-right: none;
    /* background-color: white; */
    /* outline: none; */
    /* border: none; */
}

.edit-second-column label {
    /* font-size: 14px; */
    padding-left: 5px;
}

/* filter option container CSS */
.et-filter-options-container {
    display: flex;
    flex-direction: row;
    width: 344px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    padding: 0px 10px;
    position: absolute;
    /* margin-left: 70px; */
    margin-right: 56px;
    margin-top: 96px;
    right: 0;
}

.filter-box-position{
    top: 263px !important;
    right: 44px !important;
}

.et-filter-options-for-master-ads {
    display: flex;
    flex-direction: row;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15); */
    position: absolute;
    right: 0;
    /* top: 238px; */
    top: 230px;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    z-index: 87;
    position: absolute;
    /* background: #F0EDFF; */
    border: 1px solid #4D4184;
    background-color: white;
    border-radius: 8px 8px 8px 8px;
}

.filter-box-fill {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 114px;
    height: 36px;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    background: #4D4184;
    border-radius: 8px 0px 0px 8px;
    padding: 0px 10px
}

.filter-box-tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4D4184;
    background-color: white;
}

.dash-icon {
    padding-right: 10px;
}

.selected-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;

}

/* this is will hide filter options  */
.hide {
    display: none;
}

.edit-targeting-campaign-count-btn-container {
    margin-top: 16px;
    margin-left: 20px;

}

/* main container for collective bid setting  */
.bid-config-container {
    display: flex;
    flex-direction: row;
    padding-top: 18px;

}

.main-modal-for-bid-config {
    padding: 0px;
    width: 436.2px;
    height: 254.8px;
    background: #FFFFFF;
    border-radius: 8px;

}

.bid-config-modal-container {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    /* width: 444.73px;
    height: 254.8px;
    background-color: #047857; */
}

.edit-bid-heading {
    /* width: 436.2px;
    height: 55.92px; */
    background: #F4F6F8;
    border-radius: 8px 8px 0px 0px;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1C1B1F;
    height: 55px;
}

.bid-config-content-containet {

    height: 133px;
}

.percetage-config-container {
    display: flex;
    width: 120px;
    height: 34px;
    background: #F6F4FF;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 4px;
    justify-content: space-between;
}

.bid-number {
    width: 48px;
    height: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border: none;
    padding-left: 16px;

}

.edit-ads-targetting-card-container .edit-targetting-card-header .search-and-add-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}



/* .edit-targetting-table-container table {
    overflow-x: scroll;
    width: 100%;
} */



.edit-targetting-table-container .edit-ad-table-container table tbody tr td {
    /* padding: 12px 40px; */
    border-right: 1px solid #CCCCCC;
    padding: 8px 20px;
    text-align: start;
    /* border: 1px solid  */
}

.edit-ad-table-container {
    width: 100%;
    overflow-x: auto;
    /* height: 591px; */
    height: 79vh;
    overflow-y: auto;
}

.edit-targetting-table-container .edit-ad-table-container table thead tr th:nth-child(2) {
    width: 248px;
    border-right: 1px solid #e47518;
    box-shadow: 2px 1px 6px #c9705a91;
    /* padding: 12px; */
}

.edit-targetting-table-container .edit-ad-table-container table thead {
    position: sticky;
    top: 0;
    z-index: 10;
}

.edit-targetting-table-container .edit-ad-table-container table tbody tr td:nth-child(2) {
    width: 361px;
    /* padding: 12px; */
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* padding: 12px; */
    gap: 10px;
}

.heads-with-two-words {
    width: 100px;
}

.edit-targetting-table-container .edit-ad-table-container table {
    overflow-x: auto;
    width: 100%;
}

.edit-ads-targetting-card-container .edit-targetting-card-header .serch-and-button-container {
    align-items: center;
    justify-content: flex-end;

}

/* .add-keyword-btn {
    padding-left: 20px;

} */

.edit-targetting-table-container .switch-btn-container {
    display: flex;
    justify-content: center;
}

.serch-and-button-container {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding-right: 20px;

}

.sortBox-container {
    position: absolute;
    margin-top: 50px;
    margin-left: -20px;

}

.edit-targetting-table-container .edit-ad-table-container table thead tr .et-table-heads {
    /* padding: 12px 20px; */
    border-right: 1px solid white;
    z-index: 10;
    padding: 8px 20px;
}

.edit-targetting-table-container .edit-ad-table-container table tbody .et-text-left-align,
.edit-targetting-table-container .edit-ad-table-container table thead tr .et-text-left-align {
    text-align: start;
}

.edit-targetting-table-container .edit-ad-table-container table .et-end-align,
.edit-targetting-table-container .edit-ad-table-container table thead tr .et-end-align {
    text-align: end;
}

.chip-container-targeting {
    position: absolute;
    right: 0;
    top: 3px;
}

/* .edit-targetting-table-container .edit-ad-table-container table thead tr .et-end-align {
    text-align: end;
} */

/* .edit-targetting-table-container .edit-ad-table-container table thead tr .et-text-left-align {
    text-align: start;
} */


@media only screen and (max-width: 820px) {

    .edit-ads-targetting-card-container .edit-targetting-card-header {
        display: flex;
        flex-direction: column;

    }

    .search-and-add-btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
    }

    .serch-and-button-container {
        align-items: center;
        justify-content: space-between;

    }
}

@media only screen and (max-width: 500px) {
    .serch-and-button-container {
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

    }
}