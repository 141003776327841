.selection-bar {
    display: flex;
    flex-direction: row;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15); */
    /* top: 238px; */
    /* background: #F0EDFF; */
    border: 1px solid #999EA4 ;
    background-color: white;
    border-radius: 8px 8px 8px 8px
}

.selection-bar button:last-child {
  border-radius: 0px 8px 8px 0px;
}