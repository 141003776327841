.btnGroup{
    display: flex;
    align-items: center;
    /* column-gap: 8px; */
    flex-wrap: wrap;
    row-gap: 8px;
}
.btnGroup input{
    position: absolute;
    left: -100%;
}
.btnGroup .radioBtn{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 10px;
    border: 1px solid #8C9196;
    /* border-radius: 2px; */
    flex: none;
    order: 0;
    flex-grow: 0;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #4C4F6A;
    margin-bottom: 0;
    font-weight: 500;
    width: 76px;

}
.reports .btnGroup .radioBtn{
    width: max-content;
}
.btnGroup .radioBtn:hover{
    /* background:#1F5199;
    border: 1px solid #1F5199;
    border-radius: 0px;
    color:#fff; */

    background: #EDF4FE;
    color: #1E2426;
    /* border: 1px solid #575959; */
    border-radius: 0px;
}

.radius-left {
    border-bottom-left-radius: 76px !important;
    border-top-left-radius: 76px !important;
   
}
.radius-right {
    border-bottom-right-radius: 76px !important;
    border-top-right-radius: 76px !important;
}

.btnGroup input[type="radio"]:checked + .radioBtn {
    border: 2px solid var(--mainColor);
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white;
    background: #07A9D2;
    
}
.btnGroup input[type="radio"]:checked + .radioBtn:hover {
    background: #0087A9;
    color:#FFFFFF;
}
.btnGroup input[type="radio"]:disabled + .radioBtn {
    background-color: #ccc;
    opacity: .2;
}

.btnGroup {
   
    border: 1px solid #8C9196;
    border-radius: 76px;
}
.btnGroup .radioBtn
{
	border: none;
	border-right: 1px solid #8C9196;
}
.btnGroup div:last-child .radioBtn
{
	border-right: none;
}