/* searchbar main container */
.search-bar-container-subclass .search-bar-container-for-edit-campaign {
    display: flex;
    flex-direction: row;
    /* width: 457px; */
    /* width: inherit; */
    

    height: 40px;
}
.search-bar-width{
    width: 457px;
}

/* search bar icon container */
.search-bar-container-subclass .search-bar-container-for-edit-campaign .search-icon-container {
    position: absolute;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    padding: 12px;
}

/* search icon  */
.search-bar-container-subclass .search-bar-container-for-edit-campaign .search-icon-container .search-icon {
    color: #4D4184;
}

.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-field-open {
    /* width: 457px; */
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 8px;
    color: #A7A7A7;
    font-size: 14px;
    line-height: 20px;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

/* search input field */
.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset .seacrh-input-field {
    /* width: 457px; */
    padding-left: 26px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #8C9196;
    border-radius: 8px;
    color: #222222;
    line-height: 20px;
    width: 100%;
    height: 100%;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    /* font-size: 14px; MS-96 filter font change to 12*/
    font-size: 12px;
    line-height: 20px;
    padding-left: 36px;
    /* padding-top: 5px; */

}
.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset input::placeholder{
    padding-left: 5px;
}
.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset {
    /* background-color: aqua; */
    width: 100%;

}

@media only screen and (max-width: 900px) {
    .search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset .seacrh-input-field {
        width: 300px;
        padding-left: 40px;
        align-items: center;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #BDBDC4;
        border-radius: 8px;
        color: #A7A7A7;
        font-size: 14px;
        line-height: 20px;

    }

    .edit-targetting-card-container .edit-targetting-card-header {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .search-bar-container-subclass .search-bar-container-for-edit-campaign {
        display: flex;
        flex-direction: row;
        width: 300px;
        height: 40px;
    }
    .edit-ads-targetting-card-container .edit-targetting-card-header .serch-and-button-container {
        align-items: center;
        justify-content: flex-start;
    
    }
}

@media only screen and (max-width: 400px) {
    .search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset .seacrh-input-field {
        width: 200px;
        padding-left: 40px;
        align-items: center;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #BDBDC4;
        border-radius: 8px;
        color: #A7A7A7;
        font-size: 14px;
        line-height: 20px;
    }
    .search-bar-container-subclass .search-bar-container-for-edit-campaign {
        display: flex;
        flex-direction: row;
        width: 100px;
        height: 40px;
    }

}