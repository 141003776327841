.automations-main-table-wrapper {
    max-height: 680px;
    /* overflow-y: scroll; */
}

.automations-main-table-wrapper tr td {
    font-size: 12px ! important;
    border: 1px solid #999 ! important;
    white-space: nowrap;
    text-align: left ! important
}

.automation-filter-table-body {
    height: 40vh;
}

.automation-filter-table-body  td {
    border: 1px solid #999 ! important;
}
.automation-filter-modal {
    max-width: fit-content;
}