.template-card-container {
  display: flex;
  padding: 16px 16px 20px 16px;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
    0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.template-info-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.template-info-container .template-rule-heading {
  color: var(--text-default, #202223);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.template-info-container .template-rule-desc {
  color: var(--text-subdued, #6d7175);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.template-card-container .template-chip {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
}

.template-image-container {
  width: 50px;
  height: 50px;
  background-color: #f0f0ff;
  border-radius: 3px;
}

.template-image-container img {
  object-fit: contain;
  height: 100%;
}

.template-details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.template-cta-container {
  display: flex;
  gap: 10px;
}

.template-cta-container .btn-without-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-neutral-subdued, #babfc3);
  background: var(--action-secondary-default, #fff);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
}
