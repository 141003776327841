.history-logs-table {
    border-collapse: initial !important;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container.history-logs table tr th:nth-child(2) {
 left: 109.5px !important;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container.history-logs table tbody tr .history-logs-first-td {
    position: sticky;
    left: 0;
    z-index: 35;
    background-color: #fff;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container.history-logs table tbody tr .history-logs-second-td {
    position: sticky;
    left: 109.5px;
    z-index: 35;
    background-color: #fff;
    border-right: 1px solid #cccc;
    box-shadow: 2px 1px 6px #cccc;
    height: auto;
}