.accordion .card-header:after {
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
}

.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
}

.accordian-tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordian-tit h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.accodian-main-box ul {
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
  border-top: 1px solid #E1DFEB;

}

.accodian-main-box ul li {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 10px;
  background-image: url("/src/assets/icons/check_circle_icon.svg");
  background-repeat: no-repeat;
  padding: 1px 0 0 26px;
}

.accodian-main-box ul li img {
  margin-right: 5px;
}

.accordian-tit span {
  background-image: url("/src/assets/icons/keyboard_arrow_down.svg");
  width: 13px;
  height: 7px;
  background-repeat: no-repeat;
  cursor: pointer;
  color: transparent;
}

.accordian-tit span.rotated {
  transform: rotate(180deg);
}