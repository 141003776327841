.select-option-container.multiselect-option-wrapper {
    overflow: hidden;
    max-height: 280px;
    width: max-content;
    min-width: 100%;
    top:42px;
   
}
.select-option-container.multiselect-option-wrapper.withLabel {
    padding:8px;
    top:68px;
}
.multiselect-option-wrapper .select-opt {
    font-size: 12px ! important;
    height: auto;
    padding: 0px 8px;
    font-weight: 500;
    display:flex;
    gap:8px;
    justify-content: flex-start;
    align-items: flex-start;
    white-space: nowrap;
}
.innput-container-cb.multiselect {
    height:40px;
    width:40px;
}
.select-button-container.select-div {
    border-radius: 8px;
}
.options-contaoner-cd.lsit-table-scrollbar.multiselect {
    height:auto;
    max-height: 200px;
    margin-bottom:16px;
    overflow:scroll;
    padding-bottom: 24px;
}
.custom-drop-down-saerch-box-footer.multiselect-footer {
    bottom:0px;
}

.options-contaoner-cd.lsit-table-scrollbar.multiselect::-webkit-scrollbar-button:vertical:decrement:start {
	display: none;
}

.options-contaoner-cd.lsit-table-scrollbar.multiselect::-webkit-scrollbar-button:vertical:increment:end {
	display: none;
}

.options-contaoner-cd.lsit-table-scrollbar.multiselect::-webkit-scrollbar-button:decrement:end {
	display: none;
}

.options-contaoner-cd.lsit-table-scrollbar.multiselect::-webkit-scrollbar-button:increment:start {
	display: none;
}

.options-contaoner-cd.lsit-table-scrollbar.multiselect::-webkit-scrollbar-button:increment:end {
	display: none;
}
.count-of-selcted-in-main-button-container.zero {
    background-color: #FFF;
}
.count-of-selcted-in-main-button-container {
    justify-content : center
}
.dropdown-img-select {
    border-radius: 0px 10px 10px 0px;
    height:38px;
    /* background-color: #dbd9e6; */
}
div.selected-opt-show-btn.campaign-objective {
    margin-left: 0px;
    /* font-size: 11px;; */
}

.custom-height-multiselect-filter {
    height: 20px;
}

.info-icon-multiselect {
    padding-bottom: 4px;
}