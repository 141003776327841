.edit-badge-container {
    background-color: #F1FCFF;
    border-radius: 8px;
    border: 1px solid #8C9196;
    padding: .3rem .5rem .3rem .5rem;
    font-size: 12px;
    cursor: pointer;
}

.edit-badge-container-selected {
    background-color: #5fd3f4;
    border-radius: 8px;
    border: 1px solid #8C9196;
    font-size: 12px;
    padding: .3rem .5rem .3rem .5rem;
    cursor: pointer;
}