.blueBadge{
    border-radius: 8px;
opacity: 0.8;
background: #E8FAFF;
color: #07A9D2;
font-feature-settings: 'liga' off, 'clig' off;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 166.667% */
text-align: center;
margin: 0;
}