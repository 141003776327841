/* CalloutCard.module.css */

.cardContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e3e5;
  border-radius: 4px;
  padding: 16px;
  min-height: 170px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.contentContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  flex-shrink: 0;
  margin-right: 16px;
}

.icon {
  width: 140px;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  color: var(--Neutral20, #303346);
  font-feature-settings: "liga" off, "clig" off;
  /* Heading 06 Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.description {
  font-size: 14px;
  margin: 0 0 16px 0;
  color: #303346;
}

.button {
  padding: 8px 16px;
  background-color: #07a9d2;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
