.td-with-loader .shimmerBG {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  /* background: #d4d0e6; */
  background: linear-gradient(to right, #F4F6F8 8%, #fefefe 18%, #f0efef 33%);
  /* background: #F4F6F8; */
  background-size: 1200px 100%;
}

.title-line {
  height: 16px;
  width: 50px;
  border-radius: 8px;
}

.box {
    height: 20px;
    width: 20px;
    border-radius: 2px;
    margin-top: 5px;
}

.end {
  width: 80%;
}

/* @-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
} */

/* @keyframes shimmer {
  from {transform: translateX(-100%);}
  to {transform: translateX(100%);}
} */

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
