.previewHeading {
  color: #5c5f62;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 4px;
  margin-top: 8px;
}

.previewInnerBox {
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #fafbfb;
  padding: 4px 8px;
  margin-bottom: 4px;
}

.previewText {
  color: var(--Text-Default, #202223);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  padding: 0;
  margin: 0;
}
