 .main-product-td-second {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;  
  max-width: 320px; 
  width: 250px;
}

.main-table-product-container {
  display: flex;
 

}
.defaultWidthForProduct{
  width: 600px; 
  gap: 1rem;
}
  .main-table-product-container
  .right-circle-image-container
  .image-product-table-second-td {
  height: 30px;
  width: 30px;
  background: #d9d9d9;
  border-radius: 10px;
}

.image-product-table-default-image {
  width: 30px;
height: 30px;
background: #F2F7FE;
border-radius: 100px;
display: flex;
justify-content: center;
align-items: center;
}


.product-ads-description {
  text-align: start;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  color: #4c646a;
}
