.form-control.sm {
    padding:6px 36px 6px 88px;
    height:auto ! important;
    line-height: 18px;
    border-color:#8C9196 ! important;
    color:#8C9196 ! important;
}
.form-control.sm.select {
    padding:5px 0px;
    width:72px ! important;
    line-height: 18px;
    border:1px solid transparent! important;
    border-right:1px solid #8C9196 ! important;
    border-radius:0px ! important;

}

.has-search .form-control.sm {
    width:100%;
    transition: none ! important;
}

.has-search .form-control-icon.sm {
    position: absolute;
    padding: 3px 6px 13px 6px;
    height:auto;
    width:32px;
    height:100%;
    text-align: center;
    right:0px;
    border-left:1px solid #8C9196;
}
.has-search .form-control-select.sm {
    position: absolute;
    height:100%;
    text-align: center;
    left:10px;
    top:1px;
}
.form-control.form-control-sm.sm:active, .form-control.form-control-sm.sm:focus {
    box-shadow: none ! important;
    outline:none ! important;
}