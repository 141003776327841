.popupModal {
  min-width: 1000px;
}
.logsTableHead {
  padding: 8px 20px;
  position: sticky;
  top: 0;
  z-index: 40;
  opacity: 100%;
  border-right: 1px solid #ffffff;
  text-align: left;
}

.stopLossLogsLoader {
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blueBadge {
  border-radius: 8px;
  opacity: 0.8;
  background: #e8faff;
  color: #07a9d2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  text-align: center;
  margin: 0;
  padding: 4px 6px;
}
.grayBadge {
  border-radius: 8px;
  opacity: 0.8;
  background: #F1F2F3;
  color: #4C646A;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  text-align: center;
  margin: 0;
  padding: 4px 6px;
}
.purpleBadge {
  border-radius: 8px;
  opacity: 0.8;
  background: #EDE6F6;
  color: #4D4184;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  text-align: center;
  margin: 0;
  padding: 4px 6px;
}
