
.checkbox-rb-container {
    display: flex;
    align-items: center;
}
.checkbox-rb-container .form-check{
    margin: 0px;
    outline: none;

    margin: 0px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkbox-rb-container .form-check-input{
    margin: 0px;
    outline: none;
    border: none;
}
.checkbox-rb-container .form-check{
    padding: 0px;
    margin: 0px;
    outline: none;
}
.checkbox-rb-container .form-check-input[type=checkbox] {
    /* border-radius: 0.25em; */
    background-color: white;
    border: 1px solid  #868BA0;;
    cursor: pointer;
    width: 17px;
    height: 17px;
    outline: none;


    
}

.checkbox-rb-container .form-check-input:checked {
    background-color: #2C6ECB;
    border: none;
    cursor: pointer;
    width: 17px;
    height: 17px;
    outline: none;
    border: none;
    /* border-color: #0d6efd; */
}

/* .checkbox-rb-container label {
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
    text-align: start;
    cursor:default
} */
.main-container-cb{
    display: flex;
    align-items: center;
    
}
.main-container-cb .innput-container-cb{  height: 36px;}
.innput-container-cb{
    /* background-color: red; */
    display: flex;
    background-color : transparent;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.innput-container-cb:hover{
    background-color : #F6F6F7;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: -14px; */
}
th .innput-container-cb:hover {
    background-color: #EDEEEF;
}
th .checkbox-check-bg:hover {
    background: #EDEEEF !important;
}
.ad-campaign-details .innput-container-cb:hover{
    background: #E5EFFD !important;}

.mt-campaign-details .innput-container-cb:hover{
        background: #E5EFFD !important;}
.checkbox-check-bg:hover
{
    background: #F2F7FE !important;
}
.innput-label-cb{
        align-items: center;
        padding-left: 5px;
   
        /* background-color: red; */
}
.leavle .form-check-input{
    margin-top:7px;
    /* Create campaign radio btn margin-right */
    margin-right: 12px;
}

input#increase-by, input#decrease-by,  input#set_bid_as {
    width: 16px;
    height: 16px;
}
.edit-bid-for-targetinng-dialogue .targeting-bid-config-container .action-by-btn {
    width: 135px;
}

.checkbox-container-nowidth{
    width: inherit;
}

.checkbox-container-nowidth:hover {
    width: inherit;
}

