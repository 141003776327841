.SignUp-main-container {
  width: 100%;
  height: 823px;
  display: flex;
}
.SignUp-left-box {
  width: 520px;
  height: 100%;
  background: #f3f1fc;
  box-shadow: 4px 0px 4px rgba(77, 65, 132, 0.08);
}
/* left box css */
.Signin-heading {
  /* text-align: center; */
  /* align-items: center; */
  /* margin-top: 30px; */
  padding:40px;
}
.Signin-heading > span {
  width: 436px;
  height: 64px;
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #4d4184;
}
.Signin-icon-main-box {
  /* width: 80%; */
  height: 50%;
  padding: 0px 40px;
  /* margin-top: 24px; */
  display: flex;
  flex-direction: column;
  row-gap: 36px;
}
.Signin-icon-flex-box {
  display: flex;
  /* width: 90%; */
  /* height: 50px; */
  gap: 25px;
}
.Signin-icon-flex-box-content {
  display: flex;
  flex-direction: column;
}
.Signin-icon-flex-box-content-span-1 {
  /* width: 181px; */
  /* height: 20px; */
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #132a30;
  font-weight: bold;

}
.Signin-icon-flex-box-content-span-2 {
  /* width: 264px; */
  /* height: 20px; */
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4c646a;
}
.Signin-icon-flex-box-image {
  max-width: 40px;
  min-width: 40px;
  width:40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Signin-icon-flex-box-image > image, .Signin-icon-flex-box-image > img {
  height: 24px;
  width: 24px;
  border-radius: 0px;
  /* background: #d9d9d9; */
}

/* right box css */
.SignUp-right-box {
  width: 820px;
  height: 100%;
}
.sign-up-Get-started {
  display: flex;
  width: 260px;
  height: 32px;
  margin: auto;
  text-align: center;
  justify-content: center;
  margin-top: 50px;
}
.sign-up-Get-started > span {
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #132a30;
  text-align: center;
}
.Signup-right-box-content {
  width: 65%;
  height: 80%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Signup-right-box-content-input-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Signup-label-1 {
  width: 214px;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4c646a;
}
.signup-input-1 {
  box-sizing: border-box;
  width: 495px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(77, 65, 132, 0.3);
  border-radius: 50px;
  padding-left: 25px;
  outline: none;
}
.signup-input-1-1{
  box-sizing: border-box;
  width: 495px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(77, 65, 132, 0.3);
  border-radius: 50px;
  padding-left: 25px;
  color: #4D4184;
  outline: none;
}
.check{
  position: relative;
box-sizing: border-box;
}
.check1{
  position: relative;
box-sizing: border-box;
}

.logIn-show1 {
  position: absolute;
  left: 82.32%;
  right: 16.49%;
  top: 26%;
  bottom: 55.15%;
  
}
.logIn-hide1 {
  position: absolute;
  left: 82.32%;
  right: 16.49%;
  top: 26%;
  bottom: 55.15%;
}
.logIn-show {
  position: absolute;
  left: 82.32%;
  right: 16.49%;
  top: 26.53%;
  bottom: 55.15%;
}
.logIn-hide {
  position: absolute;
  left: 82.32%;
  right: 16.49%;
  top: 26.53%;
  bottom: 55.15%;
}
.signup-input-1::placeholder {
  color: #87aab3;
  padding-left: 0px;
}
.signup-input-1-1::placeholder {
  color: #87aab3;
  padding-left: 0px;
}
.signup-number-91 {
  position: absolute;
  width: 65px;
height: 45px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #132A30;
background: #f6f4ffa2;
border-radius: 50px 0px 0px 50px;
display: flex;
justify-content: center;
text-align: center;
align-items: center;
z-index: 1;
}
#signup-input-1-number {
  padding-left: 85px;
  /* z-index: 111; */
}
.signup-number-91 > span {
  width: 25px;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #132a30;
  text-align: center;
}
.Signup-btn {
  width: 495px;
  height: 50px;
  background: #4d4184;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 50px;
  color: white;
  margin-top: 25px;
}
.Signup-TC {
  text-align: center; 
}
.Signup-TC > span {
  width: 386px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #63677B;
}
.Signup-or-div-main {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Signup-or-div-main-1 {
  width: 218px;
  height: 0px;
  border: 1px solid rgba(77, 65, 132, 0.3);
}
.Signup-or-div-main-2 {
  width: 218px;
  height: 0px;
  border: 1px solid rgba(77, 65, 132, 0.3);
}
.Signup-google-signin-main {
  display: flex;
  margin: auto;
  gap: 15px;
}
.login-with-google {
  width: 237.56px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(77, 65, 132, 0.15);
  border-radius: 50px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.google-image {
  height: 11.819787979125977px;
  width: 11.888216972351074px;
  border-radius: 0px;
}
.google-span {
  width: 121px;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4c646a;
}
.sign-up-already-account {
  margin: auto;
  margin-top: 30px;
}
.sign-up-already-account-1 {
  width: 223px;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4c646a;
}
.sign-up-already-account-2 {
  width: 223px;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4d4184;
  text-decoration: underline;
  padding-left: 5px;
}

/* responsive code */

@media screen and (max-width: 940px) {
  .SignUp-left-box {
    display: none;
  }
  .SignUp-right-box {
    width: 100%;
    height: 60rem;
    margin: auto;
  }
  .signup-input-1 {
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background: #ffffff;
    border: 1px solid rgba(77, 65, 132, 0.3);
    border-radius: 50px;
    padding-left: 25px;
  }
  .Signup-btn {
    width: 100%;
    height: 50px;
    background: #4d4184;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
    border-radius: 50px;
    color: white;
    margin-top: 25px;
  }

  .Signup-google-signin-main {
    display: flex;
    width: 100%;
    margin: auto;
    gap: 5px;
  
  }
  .login-with-google {
    width: 100%;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(77, 65, 132, 0.15);
    border-radius: 50px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: smaller;
    
  }
}

@media screen and (max-width: 414px) {
  .Signup-google-signin-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    gap: 5px;
  }
  .login-with-google {
    width: 100%;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(77, 65, 132, 0.15);
    border-radius: 50px;
    display: flex;
    /* flex-direction: column; */
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: smaller;
  }
}
