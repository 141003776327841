.row-flex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
#sp {
  position: relative;
  top: -20px;
  bottom: 20px;
}
#create-ad-group{
    position: relative;
   bottom: 10px;
    /* border: 1px solid red; */
}

#select-products {
  /* font-family: "Manrope" !important; */
}
#displayproduct {
  display: flex;
  /* margin: auto; */
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  /* margin-top: 30px; */
  /* border: 1px solid black; */
  gap:15px;
}
.displayproduct-div-1 {
  box-sizing: border-box;
  height: 724px;
  border: 1px solid #adc6cc;
  border-radius: 8px;
  width: 50%;
}
.displayproduct-div-1-span {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #132a30;
  width: 124px;
  height: 20px;
  position: relative;
  left: 22px;
  top: 25px;
}
.displayproduct-div-1 > p {
  width: 147px;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: #4c646a;
  position: relative;
  left: 23px;
  top: 25px;
}
#serach-dispaly-product {
  position: relative;
  left: 15px;
  top: 37px;
}

/* searchbar main container */
.search-bar-container2 {
  display: flex;
  flex-direction: row;
}

/* search bar icon container */
.search-bar-container2 .search-icon-container {
    position: absolute;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    padding: 12px;
    margin-left:3px
}

/* search icon  */
.search-bar-container2 .search-icon-container .search-icon {
  color: #4d4184;
}

.seacrh-input-container {
  width:78%;
}

/* search input field */
.search-bar-container2 .seacrh-input-field {
  width: 93%;
  padding-left: 40px;
  align-items: center;
  height: 40px;
  background: #ffffff;
  border: 1px solid #bdbdc4;
  font-size: 14px;
  line-height: 20px;
  border-radius: 24px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
}
.seacrh-input-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6f8c93;

  opacity: 1; /* Firefox */
}

/* // Add Product Button  */

.manual-product-add-btn button {
    margin: 3px 0px 0px 0px;
}

.manual-product-remove-btn button {
  margin: 3px 10px 0px 0px;
}

.remove-input-container {
  width: 71%;
}

@media only screen and (max-width: 820px) {
  .search-bar-container2 .seacrh-input-field {
    width: 300px;
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #ffffff;
    border: 1px solid #bdbdc4;
    border-radius: 8px;
    color: #a7a7a7;
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 450px) {
  .search-bar-container2 .seacrh-input-field {
    width: 150px;
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #ffffff;
    border: 1px solid #bdbdc4;
    border-radius: 8px;
    color: #a7a7a7;
    font-size: 14px;
    line-height: 20px;
  }
}

.SelectAll-input {
  position: relative;
  left: 16px;
  top: 60px;
  display: flex;
  text-align: center;
  z-index: 4;
  z-index: 4;
  
}
.SelectAll-input > input {
  width: 24px;
  height: 24px;
  background: #ffffff;
}

.aba[type="checkbox"] {
  accent-color: #4d4184;
}

.SelectAll-input > label {
  width: 63px;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #132a30;
  margin-left: 5px;
  padding-bottom: 3px;
}

.dispaly-product-images {
  width: 100%;
  height: 550px;
  position: relative;
  top: 58px;
  display: grid;
  left: -8px;
  grid-template-columns: repeat(3,170px);
  grid-template-rows: max-content;
  justify-content: center;
  gap:15px;
  margin-top:5px;

  
}

.ad-group-modal .dispaly-product-images {
  left: 1px !important;
}

.dispaly-product-images-open {
  width: 100%;
  height: 550px;
  position: relative;
  top: 58px;
  display: grid;
  left: -8px;
  grid-template-columns: repeat(3, 145px);
  grid-template-rows: max-content;
  justify-content: center;
  gap:15px;
  float: right;
  overflow-y: scroll;
  margin-top:5px;
}

/* MS-258 overright border */
.ad-group-modal .dispaly-product-images {
  left: 0px !important;
  gap: 8px !important;
}


.dispaly-product-images::-webkit-scrollbar {
 
}

.dispaly-product-images-open::-webkit-scrollbar {
 
}

.auomatic-inputs {
  position: relative;
  left: -67px;
  bottom: 140px;
  width: 20px;
  height: 20px;
  background: #ffffff;
}

.automatic-inputs-open {
  position: relative;
  left: -56px;
  bottom: 140px;
  width: 20px;
  height: 20px;
  background: #ffffff;
  bottom: 118px;
}

.auomatic-inputs[type="checkbox"] {
  accent-color: #4d4184;
}

.dispaly-product-images {
  float: right;
  overflow-y: scroll;
}

/*SCROLLBAR MODIFICATIONS*/

.dispaly-product-images::-webkit-scrollbar {
  width: 8px;
}

.dispaly-product-images-open .product .image-container img {
  width: 143px;
}

.dispaly-product-images::-webkit-scrollbar-thumb {
  background: #909090;
  border-radius: 8px;
}
.dispaly-product-images::-webkit-scrollbar-track {
  background: #ffffff;
}

.dispaly-product-images-card {
  /* width: 147px !important; */
  height: 180px;
  border-radius: 7px 7px 7px 7px;
  border: 1px solid#ADC6CC;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  text-align: center;
}

.dispaly-product-span-card {
  box-sizing: border-box;
  width: 100%;
  height: 39.77px;
  background: #ffffff;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #adc6cc;
  border-top: none;
  text-align: center;
}

.display-auto-span {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #132a30 !important;
  text-align: center;
  position: relative;
  bottom: 20px;
}

.min-para {
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */
margin-left: 5px;

display: flex;
align-items: center;
text-align: center;

/* Neutral40 */

color: #4F5366;
}

@media screen and (max-width:1425px) {
  .dispaly-product-images {
    grid-template-columns: repeat(3,163px);
  }
}

@media screen and (max-width:1390px) {
  .dispaly-product-images {
    grid-template-columns: repeat(3,150px);
  }

  .product .image-container img {
    width: 145px;
  }

  .dispaly-product-images-open {
    grid-template-columns: repeat(3,130px);
  }

  .dispaly-product-images-open .product .image-container img {
    width: 125px;
  }

}

@media screen and (max-width:1325px) {
  .dispaly-product-images {
    grid-template-columns: repeat(3,135px);
  }

  .dispaly-product-images-open {
    grid-template-columns: repeat(3,120px);
  }

  .dispaly-product-images-open .product .image-container img {
    width: 115px;
  }

  .product .image-container img {
    width: 130px;
    align-items: center;
  }

  .remove-input-container {
    width: 62%;
  }

  .seacrh-input-container {
    width: 70%;
}
}

@media screen and (max-width:1250px) {
  .dispaly-product-images {
    grid-template-columns: repeat(3,130px);
  }

  .product .image-container img {
    width: 125px;
    align-items: center;
  }
  .dispaly-product-images-open {
    grid-template-columns: repeat(3,110px);
  }

  .dispaly-product-images-open .product .image-container img {
    width: 105px;
  }
}

