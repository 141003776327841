*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}
.LogIn-Main-container{
    width: 100%;
    /* height: 700px; */
    display: flex;
    justify-content: space-between;
}

.Login-Error-1 {
  color: #d72c0d;
  width: 170px;
  height: 20px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
/* right side */

.LogIn-Right-box{
  width: 820px;
  height: 100%;
  margin: auto;
  padding: 50px;
}
.LogIn-Right-box-heading{
  margin: auto;
  width: 79px;
height: 32px;
/* font-family: 'Manrope'; */
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
color: #132A30;
margin-top: 30px;
}
.LogIn-Right-box-content{
  width: 70%;
  height: 80%;
  margin: auto;
  margin-top: 40px;
  
  
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
}
.LogIn-Right-box-content-input-1{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.LogIn-label-1{
  width: 170px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #4F5366;
}


.LogIn-input-1{
box-sizing: border-box;
width: 495px;
height: 45px;
background: #FFFFFF !important;
border: 1px solid rgba(77, 65, 132, 0.3);
border-radius: 50px;
padding-left: 30px;
outline: none;
}

.LogIn-input-1-error {
  box-sizing: border-box;
  width: 495px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid rgba(77, 65, 132, 0.3);
  border-radius: 50px;
  padding-left: 30px;
  outline: none;
  border-color: #d72c0d;
}

.LogIn-input-1-1{
  box-sizing: border-box;
width: 495px;
height: 45px;
background: #FFFFFF;
border: 1px solid rgba(77, 65, 132, 0.3);
border-radius: 50px;
padding-left: 30px;
color: #4D4184;
outline: none;
}
.login-eyecheck{
  position: relative;
box-sizing: border-box;
}
.logIn-show1-log {
  position: absolute;
  left: 89.32%;
  right: 16.49%;
  top: 26%;
  bottom: 55.15%;
  
}
.logIn-hide1-log {
  position: absolute;
  left: 89.32%;
  right: 16.49%;
  top: 26%;
  bottom: 55.15%;
}

.LogIn-input-1::placeholder {
  color: #87aab3;
  padding-left: 0px;
}
.LogIn-input-1-1::placeholder {
  color: #87aab3;
  padding-left: 0px;
}
.LogIn-SignIn-btn{
  align-items: center;
  margin-top: 10px;

}
.LogIn-SignIn-btn>button{
  width: 495px;
height: 50px;
background: #4D4184;
box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
border-radius: 50px;
color: white;
}
.LogIn-tc{
  width: 471px;
height: 20px;
align-items: center;
text-align: center;
margin-top: 10px;
  color: #63677B;
}

.LogIn-tc>span{
  width: 371px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #6F8C93;
}
.LogIn-account{
align-items: center;
text-align: center;
margin-right: 20px;
margin-top: 20px;
}
.LogIn-account>span{
  width: 213px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #4C646A;

}
#LogIn-account-link-span{
  text-decoration: underline;
  color: #4D4184;
  padding-left: 5px;
  cursor: pointer;
}
.LogIn-Forget-password{
  width: 475px;
height: 45px;
  display: flex;
  justify-content: space-between;

}
.LogIn-remember-me{
  display: flex;
  gap: 10px;
  margin-left: 10px;
}
.LogIn-remember-me>input{
  display: flex;
  width: 24px;
height: 24px;
left: 733px;
top: 373px;
background: #2C6ECB;
border-radius: 4px
}
.LogIn-remember-me>input[type="checkbox"] {
  accent-color: #2C6ECB;
}
.LogIn-remember-me>span{
  width: 97px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #4F5366;

}

.forget{
  width: 103px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
text-decoration-line: underline;
color: #07A9D2;
cursor: pointer;
}

/* MS-254 new login page design */
.login-logo{
  padding-left: 40px;
  padding-top: 20px;
}
.login-left-box {
  width: 520px;
  min-height: 100vh;
  background: #1E1936;
  box-shadow: 4px 0px 4px rgba(77, 65, 132, 0.08);
}

.login-heading {
  padding:40px;
}
.login-heading > span {
  width: 436px;
  height: 64px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}

.login-icon-flex-box-content-span-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  /* font-weight: bold; */

}
.login-icon-flex-box-content-span-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #E1E1E1;
}

.login-icon-flex-box-image {
  max-width: 40px;
  min-width: 40px;
  width:40px;
  height: 40px;
  background: #1E1936;
  /* box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12); */
  border: 0.5px solid #E1E1E1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-icon-flex-box-image > image, .login-icon-flex-box-image > img {
  height: 24px;
  width: 24px;
  border-radius: 0px;
  /* background: #d9d9d9; */
}
.LogIn-no-account{
  width: 471px;
height: 20px;
align-items: center;
text-align: center;
margin-top: 20px;
  color: #63677B;
}

.LogIn-no-account>span{
  width: 371px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #4F5366;
}

.signup-option{
  width: 103px;
height: 20px;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 20px;
text-decoration-line: underline;
color: #07A9D2;
cursor: pointer;
margin-left: -136px;
}


     @media screen and (max-width: 940px) {
       
      }
      
      @media screen and (max-width: 414px) {
       
      }
      