.product-ads-populate-modal .modal-body{
    height: 100%;
    min-width: 600px;
    padding: 0px;
}

.negative-targeting-modal-heading {
    font-size: 17px;
}

.product-ads-populate-modal {
    overflow: hidden;
}

.add-negative-targeting-modal-footer {
    justify-content: end;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
    margin-top: 10px;
}

.mst-negative-modal-drop-down-height {
    max-height: 300px !important;
}

.mst-negative-modal-drop-down-margin {
    margin-bottom: 70px;
}

.negative-targeting-modal-header {
    justify-content: space-between;
    display: flex;
}