/* edit campaign main container CSS  */
.edit-campaign-main-container {
  display: flex;
  flex-direction: column;
}

.ec-btns-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.edit-targetting-card-container {
  display: flex;
  /* padding: 10px 20px; */
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #cce1e6;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
}

.date-input {
  box-sizing: border-box;
  height: 40px;
  /* left: 1206px;
    top: 156px; */
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 8px;
}

.dd-container {
  display: flex;
  position: relative;
}

.edit-campaign-calendar {
  position: absolute;
  top: 45px;
  right: 0px;
  z-index: 500;
}

.edit-capmiagn-selected-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 20px;
  /* width: 134px; */
  /* height: 32px; */
  background: linear-gradient(0deg, #4d4184, #4d4184), #f1fcff;
  border-radius: 24px;
}

.footer-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0px;
}

.go-back-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 178px;
  height: 40px;
  border: 1px solid #4d4184;
  border-radius: 24px;
  align-items: center;
  text-align: center;
  color: #4d4184;
  margin-right: 10px;
}

.update-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 169px;
  height: 40px;
  background: #4d4184;
  box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
  border-radius: 24px;
  align-items: center;
  text-align: center;

  /* Background White */

  color: #ffffff;
}

.edit-targetting-table-container .edit-ad-table-container table tr th:nth-child(1) {
  /* text-align: center; */
  /* padding: 12px 40px; */
  position: sticky;
  left: 0;
  top: 0;
  z-index: 45;
  opacity: 100%;
}

/* this will make headers second column first data cell sticky */
.edit-targetting-table-container .edit-ad-table-container table tr th:nth-child(2) {
  /* text-align: center; */
  /* padding: 12px; */
  position: sticky;
  left: 135px;
  left: 76px;
  top: 0;
  z-index: 45;
  opacity: 100%;
  width: 248px;
  border-right: 1px solid #e47518;
  box-shadow: 2px 1px 6px #c9705a91;
}

.edit-targetting-table-container .edit-ad-table-container table tbody .edit-second-column {
  position: sticky;
  /* left: 135px; */
  left: 76px;
  width: 248px;
  background-color: white;
  /* padding: 12px 15px; */
  height: auto;
  border-right: 1px solid #CCCC;
  box-shadow: 2px 1px 6px #cccc;
  /* box-shadow-right: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* box-shadow: 3px 0 5px -2px #888; */
  /* border-right:1px solid black; */
}

.edit-targetting-table-container .edit-ad-table-container {
  /* height: 647px; */
  overflow-y: auto;
  height: 79vh;
}

.edit-targetting-table-container .edit-ad-table-container table {
  border-collapse: separate;
  border-spacing: 0;
}

.edit-targetting-table-container .edit-ad-table-container table tbody .edit-first-column {
  position: sticky;
  left: 0;
  opacity: 100%;
  background-color: white;
  /* padding: 12px 24px; */
}

.edit-targetting-table-container .edit-ad-table-container table tbody td {
  /* padding: 12px 30px; */
  border-bottom: 1px solid #CCCC;

}

.et-pading-bottom {
  padding-bottom: 16px;
}
.campaign-hard-text{
  /* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;
text-align: center;

color: #4C646A;
padding-right: 5px;
}

.campaign-name-inedit{
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
cursor: pointer;
/* identical to box height, or 143% */

display: flex;
align-items: center;
text-align: center;

color: #2C91FF;
padding-right: 5px;
}


.adgroup-name-inedit{
  /* font-family: 'Inter'; */
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;
text-align: center;

/* Neutral 100 */

color: #002630;
}
.color-for-highlight-campaignlist-text{
  color : #005AA4
}