.loader-main{
    left: 0;
    top:0;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    background-color: rgba(244, 246, 248, 0.6);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}
/* 
.loader-text {
   color: #4f5366;
   font-style: normal;
   font-weight: 800;
   font-size: 16px;
   line-height: 32px;
   font-family: "Manrope";
   gap:10px;
   text-align: center;

} */

#loader-parent-text {
    color: #4f5366;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 32px;
    /* font-family: "Manrope"; */
    gap:10px;
    text-align: center;
    padding-top:4px;
}

.loader-text-appended-loader {
    display: flex;
    align-items: flex-end;
    text-align: center;
    font-weight: 400;
    margin-left: 33px;
    font-weight: 600;
}