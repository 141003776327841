.overview-seeting-modal {
    width: 547px;
    padding: 20px;
    padding: 10px;
    padding-top: 20px;
}

.overview-setting-head {
    padding: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    flex-direction: column;
    color: #002630;
    padding-right: 40px;
}

.overview-seeting-modal .modal {
    display: flex;
    justify-content: center;
    margin: none;
    display: block;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.overview-set-modal-close-icon-div{
    position: relative;
    margin-top: -1.2rem;
   }
.overview-set-modal-close-icon-container {
    position: absolute;
    top:0;
    height:15px;
    cursor: pointer;
}

.small-grey-text {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
    width: 301px;
}

.instruction-heading-when-min-six-card-selected {
    /* Heading 06 Medium */

    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 80%;
    color: #132A30;
}

.instruction-heading-when-min-six-card-not-selected {
    /* Heading 06 Medium */

    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 80%;
    color: #f00a0a;
   
}
.instruction-heading-when-min-six-card-not-selected-and-clicked-closed {


    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 80%;
    color: #f00a0a;
    animation: zoom-in-zoom-out 3s ease 1;
}
.btn-alignment {
    flex: 1;
}

.instruction-heading-2 {
    display: flex;
    flex: 2;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #132A30;
}

.widgets-options {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
}

.instruction-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 12px;
}

.modal-Head {
    padding: 10px
}

.overviwSettingModal {
    width: 547px;
    margin: auto;
    /* height: 648px; */
}

.overviwSettingModal .modal-header {
    /* padding: 19px; */
    margin-top: 20px;
    padding-left: 19px;
}

.overviwSettingModal .modal-header .btn-close {
    background-color: white;
    margin-top: -130px;
}

.overviwSettingModal .modal-body {
    margin-top: -58px;
    padding: 20px;

}

.overview-modal-dialogue {
    width: 483px;

}

.overviewDialogue {
    background-color: red;
}

.overview-settng-modal-switch-container {
    background-color: aqua;
    display: flex;
    justify-content: space-between;
}

.overview-instruction-set-switch-btn .form-check {
    width: 170px;
    display: flex;
    justify-content: space-between;
}

.overview-set-switch-btn .form-check {
    justify-content: space-between;
    padding: 10px 0px;
    width: 100%;

}

@keyframes zoom-in-zoom-out {
    0% {
        font-size: 14px;
    }

    50% {
        font-size: 15px;
    }

    100% {
        font-size: 14px;
    }
}

@media only screen and (max-width: 700px) {

    .overviwSettingModal {
        width: 500px;
        margin: auto;
    }
    .overview-modal-dialogue {
        width: 450px;
        padding-right: 20px;
    
    }
}
@media only screen and (max-width: 520px) {

    .overviwSettingModal {
        width: 310px;
        margin: auto;
    }
    .overview-modal-dialogue {
        width: 250px;
        padding-right: 20px;
    
    }
}
