.sildebar-container {
  /* height: 806px; */
  min-height: 100vh;
  height:100vh;
  width: 240px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  background-color: rgba(243, 241, 252, 1);
  transition-timing-function: linear;
  margin: 72px 0px 16px 0px;
  /* position: relative; */
  /* overflow-x: auto; */
}
 

.btn-container {
  /* width: 200px; */
  /* margin-bottom: 20px; */
  /* height: 44px; */
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

button {
  border: none;
  background-color: rgba(243, 241, 252, 1);

  transition: width 0.5s;
  transition-timing-function: linear;
}

/* .sidebar-section-main-containe button{
    
} */

.campaign-not-selected-sidebar-open-arrow-close {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 0px;
  gap: 8px;
  width: 200px;
  height: 50px;
  /* background: #FFFFFF; */
  /* box-shadow: 0px 4px 20px rgb(77 65 132 / 10%); */
  border-radius: 20px;
}


.reporting-not-selected-sidebar-open-arrow-close {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: 50px;
  /* background: #FFFFFF; */
  /* box-shadow: 0px 4px 20px rgb(77 65 132 / 10%); */
  border-radius: 20px;
}

.campaign-selected-sidebar-open-arrow-close {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 200px;
  height: 50px;
  /* background: #FFFFFF; */
  /* box-shadow: 0px 4px 20px rgb(77 65 132 / 10%); */
  border-radius: 20px;
}

.campaign-selected-btn-open-sidebar-dropdown-off {
  width: 224px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 100px;
  color: #4d4184;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}


.reporting-selected-btn-open-sidebar-dropdown-off {
  width: 224px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 100px;
  color: #4d4184;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.selected-btn {
  width: 224px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 100px;
  color: #4d4184;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.sidebar-main-container {
  display: flex;
  justify-content: center; 
  /* background-color: #4D4184; */
  width: 100%;
  transition: width 2s;
  transition-timing-function: linear;
}

#create-product-set-link {
  margin-top: 17px;
}

#create-link {
  margin-top: 5px;
}

.campaign-selected-btn {
  width: 200px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 100px;
  color: #4d4184;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.campaign-selected-btn-open-dropdown {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 200px;
  height: 124px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.1);
  border-radius: 20px;
}

.campaign-not-selected-btn   {
  width: 200px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  padding-left: 15px;
  border-radius: 15px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}


.reporting-not-selected-btn {
  width: 224px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  padding-left: 15px;
  border-radius: 15px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}


.cam-drop-icon-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding-right: 12px;
}

.btn-text-container {
  width: 100%;
}

.camp-drow-down-op {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start; */
  /* margin-left: -55px; */
  margin-top: -7px;
  margin-left: -43.77px;
}

.hide {
  display: none;
}

.camp-drow-down-op ul {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d4184;
}

.sidebar-all-btn-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0px 30px 0px;
  transition: width 0.5s;
  transition-timing-function: linear;
  /* background-color: #4D4184; */
}

.camp-drow-down-op .selected-sub-text {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  text-align: left;
  color: #4d4184;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.campaign-not-selected-btn-open-sidebar-dropdown  {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: max-content;
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
}

.reporting-not-selected-btn-open-sidebar-dropdown {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: auto; 
  /* height: 124px;  */
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
}

.reporting-not-selected-perf-view-selected-btn-open-sidebar-dropdown {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: auto;
  /* height: 124px; */
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
}


.reporting-not-selected-comp-view-selected-btn-open-sidebar-dropdown {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: max-content;
  /* height: 124px; */
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
}

.campaign-selected-btn-open-sidebar-dropdown   {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: 100%;
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
  background-color: white;
}

.reporting-selected-btn-open-sidebar-dropdown  {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height:max-content;
  /* height: 158px; */
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
  background-color: white;
}


.reporting-selected-perf-view-btn-open-sidebar-dropdown  {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: auto;
  /* height: 124px; */
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
  background-color: white;
}


.reporting-selected-comp-view-btn-open-sidebar-dropdown  {
  display: flex;
  flex-direction: row;
  padding: 10px 12px 0px;
  gap: 8px;
  width: 224px;
  height: max-content;
  /* height: 124px; */
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 20px;
  background-color: white;
}

.camp-drow-down-op .not-selected-sub-text {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  text-align: left;
  color: #333;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.selected-btn-oncolapse-mode {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
  border-radius: 150px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.campaign-not-selected-btn:hover {
  background: #ffffff;
  border-radius: 150px;
  transition: width 0.5s;
  transition-timing-function: linear;
  color: #4d4184;
}

.reporting-not-selected-btn:hover {
  background: #ffffff;
  border-radius: 150px;
  transition: width 0.5s;
  transition-timing-function: linear;
  color: #4d4184;
}

.selected-btn-oncolapse-mode:hover {
  background: #ffffff;
  border-radius: 150px;
  transition: width 0.5s;
  transition-timing-function: linear;
  color: #4d4184;
}

.not-selected-btn-oncolapse-mode {
  width: 60px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  padding-left: 15px;
  width: 53px;
  height: 44px;
  /* position: relative; */
}

.not-selected-btn-oncolapse-mode:hover {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
}

.not-selected-btn-oncolapse-mode:hover {
  background: #ffffff;
  border-radius: 150px;
  transition: width 0.5s;
  transition-timing-function: linear;
  color: #4d4184;
}

.not-selected-btn {
  width: 224px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  padding-left: 15px;
  border-radius: 15px;
  /* transition:  width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.not-selected-btn:hover {
  background: #ffffff;
  border-radius: 180px;
  transition: width 0.5s;
  transition-timing-function: linear;
  color: #4d4184;
}

.sidebar-btn-btn-text:hover {
  color: #4d4184;
}

.sidebar-btn-btn-text {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: #4d4184;
  padding-left: 8px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
  white-space: nowrap;
}
.disabled-category {
  cursor: not-allowed;
  opacity: 0.7;
}

.selected-btn-text:hover {
  color: #4d4184;
}

.selected-btn-text {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: #4d4184;
  padding-left: 8px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
  white-space: nowrap;
}

.sildebar-container-oncolapse-mode {
  height: 803px;
  height: 100vh;
  /* overflow: auto; */
  width: 96px;
  background: #f3f1fc;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
  /* margin-top: -20px; */
  margin-top: 72px;
  z-index: 100;
  background-color: rgba(243, 241, 252, 1);
  border: none;
  /* position: relative; */
}

.btn-container-oncolapse-mode {
  width: 53px;
  height: 44px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.sidebar-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* transition: width 0.5s; */
  transition: width 0.5s;
  transition-timing-function: linear;
}

.back-arrow-container {
  /* position: fixed; */
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid rgba(249, 249, 253, 1);
  text-align: center;
  margin-top: 10px;
  margin-left: -16px;
  transition: margin-left 0.5s;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9fd;
  position: absolute;
  right: -14px;
  top: 19px;
}

.back-arrow-container-collapse {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid rgba(249, 249, 253, 1);
  text-align: center;
  margin-top: 10px;
  margin-left: -16px;
  transition: margin-left 0.5s;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9fd;
  position: absolute;
  right: -14px;
  top: 22px;
}

.back-arrow {
  color: #4d4184;
  cursor: pointer;
}

.sidebar-icon {
  height:24px;
  width: 24px;
}
.coming-txt
{
  font-size: 10px;
    line-height: 15px;
    background: #EF4C23;
    color: #fff;
    padding: 5px 8px;
    border-radius: 20px;
    margin: 0 0 0 7px;
}

.sub-menu-absulate .main-menu-item {
  margin:2px auto;
  text-align: start;
  color: #fff;
  font-weight: normal;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: inherit;
  padding-right: 8px;
}

.sub-menu-absulate .main-menu-item:hover {
  background-color: #403B54;
}

.sub-menu-absulate .main-menu-item span {
  margin-left: 10px;
  font-size: 12px;
}

.main-menu-item.active-sub-link {
  border-left: 3px solid white;
  background-color: #403B54;
}

.main-menu-item.active-sub-link span{
 margin-left: 8px;
}

.sidebar-container{z-index:999;}
.sub-drow-down-op {
   position: fixed;
    /* top: 0;
    bottom: 0; */
    /* width: 204px !important; */
    left: 140px;
    /* height: 100px; */
    min-width: 150px !important;
    width: fit-content !important;
    z-index: 2300;
    /* margin-top: calc(12px); */
    /* left: 130px; */
    /* margin: 0; */
    background: #262046;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 4px 4px 0px;
    /* padding: 16px 12px; */
    /* border-left: 1px solid #fff; */
    padding-left: 0;
    padding-right: 0;
    /* position: absolute;
    top: 0;
    width: 204px !important;
    height: auto;
    z-index: 9;
    margin: 0;
    left: 73px;
    background: #FAFAFA;
    padding: 16px 12px; */
}

.sidebar-all-btn-main-container .collapse:not(.show) {display: block;}
.sidebar-all-btn-main-container .cam-drop-icon-text-container{display:none;}

.sidebar-btn-btn-text {
    display: none;
}

.sub-drow-down-op .not-selected-sub-text,
.sub-drow-down-op .selected-sub-text {
    padding: 12px;
    margin-bottom: 0;
}
.sub-drow-down-op .not-selected-sub-text.active,
.sub-drow-down-op .selected-sub-text.active{
    background: #fff;
    border-radius: 12px;
}

.sidebar-all-btn-main-container button > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:6px;
}

.sidebar-all-btn-main-container button > span > span {
    font-size: 12px;
    color: #fff;
}

.sidebar-all-btn-main-container button {
    padding: 0;
    width: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    background: transparent;
   
}

.selected-btn-oncolapse-mode {
    background: transparent;
    box-shadow: none;
    /* position: relative; */
}

.sidebar-all-btn-main-container button:hover {
    /* background: #5A5571; */
    /* background: rgba(255, 255, 255, 0.2); */
    background: #322E48;
    box-shadow: none;
    border-radius: 0px;
    
}
.sidebar-all-btn-main-container button.not-selected-btn-oncolapse-mode
{
  padding: 6px 0 !important;
}
.sidebar-all-btn-main-container button.selected-btn-oncolapse-mode
{
  background: #403B54;
  border-radius: 0px;
  padding: 6px 0;
  font-weight: bold;
}

.sidebar-all-btn-main-container button > span > img {
    width: 24px !important;
    height: 24px;
    /* object-fit: scale-down; */
    border-radius: 8px;
    /* padding: 4px; */
}
.sidebar-all-btn-main-container .d-flex.justify-content-center.btn-container.align-items-center:nth-child(4) button span img
{
  width: 56px !important;
}
.sidebar-all-btn-main-container .d-flex.justify-content-center.btn-container.align-items-center:nth-child(1) button span img
{
  width: 60px !important;
}
.sidebar-all-btn-main-container .d-flex.justify-content-center.btn-container.align-items-center:nth-child(4) button.selected-btn-oncolapse-mode span img {
  width: 23px !important;
}
.sildebar-container-oncolapse-mode {
    background: #1E1936;
    margin-top: 0;
}
.sildebar-container-oncolapse-mode .logo-img{
  width: 100%;
  text-align: center;
  margin-top: 17px;
  margin-bottom: 20px;
}

.sidebar-all-btn-main-container button:hover > span > img,
.selected-btn-oncolapse-mode  > span > img{
  /* background: #5A5571; */
   background: transparent}
.sidebar-all-btn-main-container button > span.create-item > img {
  height: 40px;
}

.sidebar-all-btn-main-container button > span.create-item {
  margin-bottom: 15px;
}

.sildebar-container-oncolapse-mode::-webkit-scrollbar {
  display: none;
}
/* .sildebar-container-oncolapse-mode:hover::-webkit-scrollbar {
  display: block;
} */





.sub-bg-white
{
  background: #FFFFFF;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}
.sub-bg-white.selected
{
  /* background: #E8FAFF; */
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
border: 2px solid #000000 !important;
}
.sub-bg-white:hover
{
  background: #f4f6f8;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}
.sub-bg-white .sub-main-tit
{
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding-left: 8px;
  text-align: left;
}
.sub-bg-white .mb-12{margin-bottom: 12px;}
.sub-bg-white .sub-main-desc
{
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  text-align: left;
}
button.selected-btn-oncolapse-mode, button.not-selected-btn-oncolapse-mode{cursor: inherit;}
.sidebar-all-btn-main-container button > span > span.coming-txt
{
  background: #07A9D2;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  border-radius: 100px;
  padding: 0 4px;
  margin: 0 0 0 0;
  margin-top: -4px;
}
.sub-main-tit u
{
  text-underline-offset: 4px;
}
.sidebar-all-btn-main-container button
{
  width: 100px;
  display:block !important;
  cursor: pointer;
}
.sidebar-all-btn-main-container button#create
{
  width: 60px;
  height: 60px;
  margin: auto;
  padding: 3px !important;
}

.sidebar-all-btn-main-container button#create:hover, button#create.selected-btn-oncolapse-mode  {
  background: #5A5571;
  box-shadow: none;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  padding: 3px !important;
}
.sidebar-all-btn-main-container  button#create > span > img
{
  
    position: relative;
    top: 7px;

}
.sidebar-all-btn-main-container  button#create:hover > span > img, button#create.selected-btn-oncolapse-mode > span > img 
{
  background: none;
    position: relative;
    top: 7px;

}
.sub-menu-absulate
{
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  /* overflow-y: scroll; */
  /* height: 100vh; */
  /* padding: 0 12px; */
}
.sub-menu-absulate::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.chip-inside-sidebar-action {
  right: 8px;
  top: 4px;
}

.sub-menu-absulate a{padding: 2px;display: block;}
@media (max-width: 991px) {
  .sildebar-container {
    position: fixed;
    left: 0;
    z-index: 100;
    /* transition: width 0.5s; */
    transition: width 0.5s;
    transition-timing-function: linear;
  }

  .sildebar-container-oncolapse-mode {
    position: fixed;
    left: -240px;
    margin-top: 72px;
    z-index: 9;
    /* padding-top: 72px; */
    background: #f3f1fc;
    width: 240px;
    /* transition: width 0.5s; */
    transition: width 0.5s;
    background-color: rgba(243, 241, 252, 1);
    transition-timing-function: linear;
    height: 1000vh;
  }

  .selected-btn-oncolapse-mode {
    width: 200px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(77 65 132 / 10%);
    border-radius: 100px;
    /* transition: width 0.5s; */
    transition: width 0.5s;
    transition-timing-function: linear;
  }

  .not-selected-btn-oncolapse-mode {
    width: 200px;
    height: 44px;
    display: flex;
    flex-direction: row;

    align-items: center;
    border-radius: 15px;
    padding-left: 15px;
    border-radius: 15px;
    /* transition: width 0.5s; */
    transition: width 0.5s;
    transition-timing-function: linear;
  }

  .sidebar-container .collapse:not(.show) {
    display: block;
  }

  .back-arrow-container {
    right: -28px;
  }
}