.radio-btn-container {
padding: 3px;

}

.radio-btn-container:hover {
    cursor: pointer;
}

.radio-btn-container .form-check {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* background-color: white; */
    color: #4C646A;
    pointer-events: auto;
}
/* .radio-btn-container */
.form-check-input:checked {
    /* border: 4px #4D4184 solid; */
    border: 4px #2C6ECB solid;
    background-image: url(../../../assets/icons/white-circle-icon.png);
}
.form-check-input:checked[type="radio"] {
    /* border: 4px #4D4184 solid; */
    border: 4px #2C6ECB solid;
    background-image: url(../../../assets/icons/white-circle-icon.png);
}



.leavle:hover {
    cursor: pointer;
}

label:hover {
    cursor: pointer;
}