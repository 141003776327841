.header-filter-main-container {
    /* position: absolute; */
    width: 162px;
    height: 124px;
    /* left: 841px;
    top: 346px; */

    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
    border-radius: 8px;
}

.sort-by-text {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    /* Neutral10 */
    border: 1px solid rgba(77, 65, 132, 0.15);
    color: #1F2234;
}

.selected-text {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    height: 40px;
    border: 1px solid rgba(77, 65, 132, 0.15);
    /* Neutral20 */

    color: #303346;
    display: flex;
    align-items: center;
}

.not-selected-text {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    /* border: 1px solid rgba(77, 65, 132, 0.15); */
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;

    /* Neutral50 */

    color: #63677B;
}

.selected-text .form-check-inline {

    margin-left: 10px;
}

.not-selected-text .form-check-inline {

    margin-left: 10px;

}