.main-container-for-chips {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-items: center;
}

.chip-box-conrainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 183px; */
    /* width: auto; */
    width: max-content;
    height: 32px;
    background: #b0eeff;
    border-radius: 8px;
    padding: 8px;
    margin-left: 10px;
    margin-bottom: 10px;


}

.chip-box-conrainer:hover {
    background-color: #7dd2e9;
}

.chip-box-field-name-text {
    font-size: 14px;
    color: #625B71;
    width: auto;
    white-space: nowrap;
    padding-right: 5px;
}

.chip-box-field-name-selected-opt-text {
    color: #1D192B;
    font-size: 14px;
    white-space: nowrap;
    width: auto;
    /* width: fit-content; */
    /* width: 50px;  */
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    /* border: 1px solid #000000; */
}

.hideExtraTxt {
    display: none;
}

.show-all-checked-filtering:hover+.hideExtraTxt {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    text-overflow: clip;
    word-break: break-all;
    overflow: hidden;
    display: block;
    width: 200px;
    height: auto;
    position: absolute;
    border: 1px solid grey;
    z-index: 70;
    overflow-y: auto;
    background-color: rgb(210, 201, 201);
}

.reset-btn-for-filter-btn {
    width: 80px;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #4D4184;
    /* filter: drop-shadow(0px 4px 20px rgba(77, 65, 132, 0.2)); */
    border-radius: 24px;
    /* background-color: aqua;
background-color: transparent; */
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    background: #F9F9F9;


}

.reset-icon-img {
    height: 18px;
}

.text-color-for-reset-btn {
    /* color: #4D4184;
	font-weight: 700;
font-size: 14px; */
    padding-left: 4px;

}

.above-two-chip-data-value-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    height: 20px;
    background: #1192c0;
    border-radius: 35px;
    align-items: center;
    width: 45px;
    justify-content: center;
    color: white;
    margin-left: 8px;
    font-size: 12px;
}
.reset-btn-for-filter-btn {
    width: 80px;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #4D4184; */
    /* filter: drop-shadow(0px 4px 20px rgba(77, 65, 132, 0.2)); */
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background: #F9F9F9; */

    background: #fff !important;
    color: #07A9D2 !important;
    border: 1px solid #999EA4 !important;


}

.text-color-for-reset-btn {
    /* color: #4D4184;
	font-weight: 700;
font-size: 14px; */
    padding-left: 4px;

}