.line-graph-container {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
  margin-top: 20px;
  height: 460px;
}

.line-graph-heading {
  justify-content: space-between;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #002630;
  gap: 10px;
  flex-wrap: wrap;
  /* font-family: "Manrope"; */
}

.text-sizes {
  font-size: 16px;
}

.line-graph-main {
  min-width: 300px;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.select-line-container {
  justify-content: start;
  align-items: start;
  display: flex;
  align-content: flex-start;
  column-gap: 10px;
}

.select-pie-type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 12px;
  gap: 8px;
  width: 112px;
  height: 32px;
  border: 1px solid #bdbdc3;
  border-radius: 24px;
  padding-top: 2.4px;
  color: #4c4f6a;
  margin-left: 34%;
  width: 145px;
  height: 36px;
}

.select-line-type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 12px;
  gap: 8px;
  width: 141px;
  height: 36px;
  border: 1px solid #bdbdc3;
  border-radius: 24px;
  padding-top: 0px;
  color: #4c4f6a;
  font-weight: 500;
  font-size: 14px;
  outline: none;
}
.spent-color {
  border: 1px solid #A397DB  !important;
}
.impression-color {
  border: 1px solid #70BDCC  !important;
}
.select-line-type:hover {
  background: rgba(229, 225, 249, 0.5);
  border-radius: 24px;
  color: #4c4f6a;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAACXU8ZrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgBXcsxDgFBGAXg/59xAEfYRLFRKcQBdCSTzRxhS6bhBhs3UIljIAodagolotgjrN7Ms38xyWZf+d73eGrmViseHA+bFbWSWbf4efoqdPAAU24yVzSBsa7woGXQ4aI/z3vV6w/3TLxO01H3/bpdBQCUew7j025bcnxO7CzRUOcalyAkEcimIpJCBiJUTSD5AyDKPf2ZGzDaAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 50%;
}

.line-graph-foter {
  /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 28rem; */
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  width: 70%;
  margin: auto 30px;
  padding-top: 10px;
}

.arrow_box {
  position: relative;
  background-color: white;
  width: 166px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings: "liga" off, "kern" off;
  color: #000000;
  text-align: left;
}
.arrow_box:after,
.arrow_box:before {
  right: 35%;
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  transform: rotate(-90deg);
  bottom: -90%;
}
.arrow_box_date {
  width: 100%;
  height: 20px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  color: rgba(76, 79, 106, 0.8);
}
.apexcharts-tooltip {
  color: #fff;
  overflow: visible !important;
  white-space: normal !important;
}

/* .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
} */
#chart {
  /* border: 1px solid red; */
}
@media screen and (max-width: 500px) {
  .line-graph-heading,
  .select-line-container {
    justify-content: center;
    flex-wrap: wrap;
  }
}
