.main-layout {
    display: flex;
    width: 100%;
    /* margin-top: 20px; */
    /* margin-top: 92px; */
}


.main-layout-on-open-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px 30px;
    overflow-x: hidden;
    /* margin-left: 246px; */
    /* transition:  margin-left 1s; */
    transition:  margin-left 0.5s;
    transition-timing-function: ease-in-out;
    padding-bottom: 60px;
    
}

.main-layout-on-close-sidebar {

    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 2px 40px; */
    overflow-x: hidden;
    /* margin-left: 90px; */
    /* transition:  margin-left 1s; */
    transition:  margin-left 0.5s;
    transition-timing-function: ease-in-out;
    padding-bottom: 60px;
}

.sidebar-container {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    top:0
}

@media (max-width: 991px) {
    .main-layout-on-open-sidebar {
        margin-left: 240px; 
        transition:  margin-left 1s;
        transition-timing-function: ease-in-out;
        padding-bottom: 60px;
    }
    
    .main-layout-on-close-sidebar {
        margin-left: 10px;
        transition:  margin-left 1s;
        transition-timing-function: ease-in-out;
        padding-bottom: 60px;

    
    }
    
}