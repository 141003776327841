.main-calender-container {
    background-color: aqua;
    width: 852.4px;
    height: 304.03px;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    margin-left: 30px;
    box-sizing: border-box;


}

.date-picker {
    background: rgba(255, 131, 100, 0.861);
    border-radius: 20px;
}

.calender-container {
    display: flex;
    flex-direction: row;
    background-color: aqua;
}

.calender-tile {
    background-color: white;
}

.tile-className {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #4F5366;
    background-color: white;
    padding: 3px;
    display: flex;
    justify-content: center;

}

.navigationAriaLabel {
    background-color: red;
}

.navigation-label {
    background-color: blue;
}

.next {
    color: green;
}
.btn-absualte
{
    /* position: absolute;
    right: 10px;
    bottom: 10px; */
    border-top: solid 1px #eff2f7;
    padding: 10px;
}
.btn-absualte button{
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 14px;
    padding: 5px 20px !important;
    width: auto;
    min-width: 100px;
}
.btn-absualte button:hover
{
    box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
    border-radius: 24px;
}
.calender-indashboard-container, .calender-masterads-container, .calender-inclasslist-container, .calender-margin-top-when-sidebar-open-for-campaignlist                                                                                                                                                                     
{
    width: 625px !important;
    /* height: 365px !important; */
    overflow-y: auto;
    padding-top: 0 !important;
    margin-top: 50px !important;
}
.calendar-label-multirange {
    z-index: 10000;
    display: flex;
    flex-direction: column;
    gap: 1.9rem;
    top: 0px;
    left: 34%;
}

.calendar-label-multirange small {
    font-size: .6rem;
}

.rdrDateDisplay {
    margin: 1em;
    margin-bottom: 1.2em;
    padding: 2px;
}


/* Custom Date Inputs Colors while using multicalendar-dates */
.multicalendar-dates .rdrDateDisplay:nth-child(1) .rdrDateInput:nth-child(1) {
    background-color: rgb(7, 169, 210);   
}

.multicalendar-dates .rdrDateDisplay:nth-child(1) .rdrDateInput:nth-child(2) {
    background-color: rgb(7, 169, 210);

}

.multicalendar-dates .rdrDateDisplay:nth-child(2) .rdrDateInput:nth-child(1) {
    background-color: rgb(61, 145, 255);
}

.multicalendar-dates .rdrDateDisplay:nth-child(2) .rdrDateInput:nth-child(2) {
    background-color: rgb(61, 145, 255);
}

.multicalendar-dates .rdrDateInput input {
    color: #fff;
}

.multicalendar-dates .rdrDateDisplayItemActive {
    border-color: #fff;
    border: 1.7px solid #fff;
}

.calender-masterads-container{ margin-top: 0px !important;}
.calender-indashboard-container{ margin-top: 0px !important;}
.calender-masterads-container.calender-mastersearch{top:60px !important;}

.rdrDateRangePickerWrapper { width: 625px !important;margin-top: 0 !important;position: relative;z-index: 9999;}
.calender-masterads-container{padding-top: 0 !important;}

.dashboard-calender-main{width: 100% !important;}
.rdrDefinedRangesWrapper{width:200px !important}
.rdrCalendarWrapper.rdrDateRangeWrapper{width: calc(100% - 200px) !important;}
.rdrInputRanges{display: none;}