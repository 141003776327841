/* .modal-content{
    width: max-content;
} */
.modal-header{
    padding: 20px;
    padding-bottom: 0px;
    /* border: none; */
}
/* .modal-header .btn-close{
    margin-top: -136px;
} */
