.switch-btn-container .form-check-input {
    background-color: #FFFFFF;
    border-color: #2C6ECB !important;
    background-image: url("../../assets//icons//Handle (1).svg");
    border-radius: 35px;
    /* width: 39px;
    height: 24px; */
    width: 36px;
    height: 20px;
    border: 2px solid #2C6ECB !important;
    cursor: pointer;
}

.switch-btn-container {
    display: flex;
    width: 100%;
    display: flex;

}

.switch-btn-container .form-switch {

    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 100%; */
    /* justify-content: space-between;
    padding: 10px 0px;
} */
}

.switch-btn-container .form-check-input:checked {
    /* width: 39px;
    height: 24px; */
    width: 36px;
    height: 20px;
    background-color: #2C6ECB !important;
    border-color: #2C6ECB !important;
    border-radius: 35px;
    background-image: url("../../assets//icons//white-circle-icon.png");
    cursor: pointer;
}



.switch-btn-container .form-check-label {
    width: 100%;

}

.switch-btn-container .form-switch .label-container {}


.switch-btn-container .form-switch .label-container .form-check-label {}

.switch-btn-container .form-switch .input-container {}

.switch-btn-container .form-switch .form-check-input {}