*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.AuthNav-main-container{ 
width: 100%;
height: 80px;
background: #F9F9F9;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
display: flex;
align-items: center;
}
.AuthNav-main-container-1{
    padding-left: 19px;
}
.AuthNav-icon{
    height: 37px;
width: 150px;
border-radius: 0px;
}