.section-title-container h3 {
    display: flex;
    font-weight: 700;
    /* font-size: 24px; change in MS-96*/
    font-size: 20px; 



}
.title{
/* font-family: 'Manrope'; */
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
color: #002630;
}