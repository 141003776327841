.uploader-image {
    width: 50%;
}

.card-container-for-setup {
    box-shadow: 0 2px 10px rgba(243, 241, 252, 1);
    /* height: 600px; */
    padding: 15px;
}

.card-body-container {
    background-color: #f3f1fc;
    padding: 10px;
}
button.mr-3{margin-right: 16px !important;}

table.setup-table tr td:not(:first-child):not(:nth-child(2)) {
    white-space: nowrap;
}

table.setup-table tr td:first-child {
    min-width: 120px;
}

table.setup-table tr td:nth-child(2) {
    min-width: 200px;
}
.select-option-container.multiselect-option-wrapper.setup-filter {
    top: 64px !important;
}
.master-ads-table.setup-table{
    border-collapse: initial;
}
.setup-dropdown .w-100{
    width: 180px !important;
}
.dynamic-width-search-bar.w-100{
    width: 100% !important;
}