

.optimizer-main-table .main-targeting-table-body-container .optimizer-table {
  border-collapse: separate;
  border-spacing: 0;
  font-weight: bolder;
}

.optimizer-main-table
  .main-targeting-table-body-container
  .optimizer-table
  thead
  tr
  th:nth-child(1) {
  position: sticky;
  z-index: 45;
  left: 0;
  opacity: 100%;
  width: 115px;
  position: sticky;
}

.optimizer-main-table
  .main-targeting-table-body-container
  .optimizer-table
  thead
  tr
  th:nth-child(2) {
    text-align: center;
    padding: 0px 20px;
    position: sticky;
    left: 100px;
    top: 0;
    z-index: 45;
    opacity: 100%;
    border-right: 1px solid white;
    justify-content: flex-start;
    border-right: 1px solid #e47518;
    box-shadow: 2px 1px 6px #c9705a91;
  }

.optimizer-main-table
  .main-targeting-table-body-container
  .optimizer-table
  tr
  .targeting-table-heads {
  text-align: start;
  padding: 8px 20px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 40;
  opacity: 100%;
  border-right: 1px solid white;
}

.optimizer-main-table
  .main-targeting-table-body-container
  .optimizer-table
  tbody
  .first-column {
    position: sticky;
    left: 0;
    z-index: 35;
    opacity: 100%;
    background-color: white !important;
    padding: 8px 20px;
  }

  .optimizer-main-table .main-targeting-table-body-container tbody .highlight .first-column {
    background-color: #C5DCFC ! important;
  }

  .optimizer-main-table
  .main-targeting-table-body-container
  .optimizer-table
  tbody
  .second-column {
    position: sticky;
    left: 100px;
    z-index: 21;
    background-color: white;
    padding: 4px 20px;
    height: auto;
    border-right: 1px solid #CCCC;
    box-shadow: 2px 1px 6px #cccc;
   }

  .optimizer-main-table
  .main-targeting-table-body-container
  .optimizer-table
  tbody tr td {
    border: none;
    border-right: 1px solid #CCCC;
    border-bottom: 1px solid #CCCC;
    padding: 8px 20px;
    text-align: left;
  }

  .optimizer-main-table
  .main-targeting-table-body-container
  .optimizer-table
  tbody .childRows td {
    background-color: #F2F7FE !important;
  }

  .optimizer-dropdown {
    width: 120px !important;
  }
