.campaign-list-container .page-main-tit {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #002630;
  margin-bottom: 20px;
}

.campaign-list-container .white-box-shedow {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
  padding: 8px;
  /* margin: 8px; */
  border-bottom: 2px solid #f1f1f1;
}

.campaign-list-container .page-sub-tit {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  /* margin-bottom: 20px; */
}

/* .campaign-list-container .box_gray
{
  background: #fff;
  margin-bottom: 20px;
} */

.campaign-list-container .box_gray {
  background: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-list-container .h-225 {
  height: 225px;
}

.campaign-list-container .page-list-tit {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
}

.campaign-list-container .page-list-desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width:250px;
  /* margin-bottom: 20px; */
  /* min-height: 110px */
}

.campaign-list-container .view-report-btn {
  background: #07A9D2;
  box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
  border-radius: 24px;
  padding: 10px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  text-align: center;
  width: 100%;
}

/* .campaign-list-container .col-md-4:nth-child(2n+1){margin-bottom: 20px;}
.campaign-list-container .col-md-3:nth-child(3n+1){margin-bottom: 20px;}
.page-sub-tit.d-flex .tooltip-container {padding-left: 8px;} */

.campaign-list-container .box-content {
  /* padding: 0 20px; */
}

.campaign-list-container .image-wrapper {
  width: 100%;
  /* height: 225px; */
  position: relative;
}

.campaign-list-container .image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

.campaign-list-container .responsive-image {
  max-width: 100%;
  max-height: 100%;
}

.reportPageImages {
  height: 150px;
  width: 250px;
}
.white-box-shedow.highlightBox {
  margin-left: -8px;
  z-index:99;
  box-shadow: 12px 7px 19px rgba(77, 65, 132, 0.22);
}
.justify-content-evenly {
  justify-content: space-evenly!important;
}
.overflowyauto{
  overflow: visible ! important;
}
@media (min-width: 1300px) and (max-width: 1550px) {
  /* .campaign-list-container .page-list-desc {
    min-height: 162px;
  } */
}