.campaign-list-table .ad-campaign-details td {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    background-color: #f3f1fc;
    text-align: left;
    border-right: 0.5px solid #cccccc;
    border-bottom: 0.5px solid #cccccc;
}


.product-report-container .form-control.sm.select {
    padding: 5px 0px;
    width: 118px ! important;
    line-height: 18px;
    border: 1px solid  !important;
    border-right: 1px solid #8C9196 ! important;
    border-radius: 8px ! important;
}

.multiple-filters-wrapper {
    flex-basis: 100%;
}

.multiple-filters-wrapper .dropdownContentWrapper .filterItem .multiselect-option-wrapper {
    min-width: 160px;
}

.product-report-group-by-filter {
    min-width: 140px;
    width: inherit;
}

