.clear-draft-bt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 115px;
    height: 40px;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    color: #4F5366;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.search-for-draft-contaier {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 10px 16px; */

    width: 758px;
    height: 40px;
    margin-top: 40px;

    /* Background White */

    background: #FFFFFF;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
}

.draft-input-box input {
    /* padding-left: 10px; */
    Width: 755px;
    height: 35px;
    padding-left: 30px;
    border-radius: 8px;
    /* position: relative; */
    /* padding: 10px 16px; */
}

.draft-input-box ::placeholder {
    border: none;
    outline: none;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    color: #A7A7A7;
    justify-content: flex-start;


}

.draft-search-input-text {
    border: none;
}

.search-icon-indraft {
    position: absolute;
    margin-top: -30px;
    margin-left: 10px;
    z-index: 100;
}

.draft-btn-width {
    width: 116px;
    height: 35px;

}


/* //parent contaninner of table */
.draft-cammpaign-list-table-data .draft-campaign-list-main-table .draft-table-conntainer {
    height: 519px;
    overflow-y: scroll;
}

.draft-cammpaign-list-table-data .draft-campaign-list-main-table .draft-table-conntainer table {
    width: 100%;
}

.draft-cammpaign-list-table-data .draft-campaign-list-main-table .draft-table-conntainer table thead .draft-table-head {
    padding: 10px 12px;
    border-right: 1px solid white;
}

.draft-cammpaign-list-table-data .draft-campaign-list-main-table .draft-table-conntainer table tbody .draft-table-row {
    border-right: 1px solid #CCCCCC;
    padding: 14px 20px;
}

.draft-campaign-name-text {
    padding-left: 50px;
}

.draft-campaign-name-text-with-alert {
    padding-left: 30px;
}

.adgroup-data-main-cotainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 150px; */
}

.adgroup-name-text {
    margin-right: 30px;
}

.sorting-campaign-mmainn-conntainer {
    box-sizing: border-box;
    width: 214px;
    height: 166px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    position: absolute;
    margin-left: -200px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
}

.sort-box-title {
    width: 100%;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1F2234;
    border-bottom: 1px solid rgba(77, 65, 132, 0.15);
    height: 30px;
    display: flex;
    justify-content: flex-start;
}

.draft-campaign-name-conntainer {
    position: relative;
}

.draft-campaignn-sort-option-container {
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
}

.draft-campaignn-sort-option-container label {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
    /* identical to box height, or 143% */


    /* Neutral50 */

    color: #63677B;
}
.back-arrow-padding{
    margin: -9px 10px 0 0;
    display: inline-block;
}
/* .adgroup-name-text {
    padding-right: 20px;
} */