.card-onSidebarClose {
  width: inherit;
  height: 72px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
}

.card-onSidebarOpen {
  width: inherit;
  height: 72px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
}

.title-heading-primary {
  width: 49px;
  height: 16px;

  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;

  color: rgba(76, 79, 106, 0.8);
}

.col-1-container {
  display: flex;
  justify-content: space-between;
  gap: 2px;
}

.percentage-desc {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
}

.percentage-up-down-container {
  display: flex;
  gap: 2px;
}

.minicard-container-on-sidebar-open {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  padding-right: 14px;
  margin-top: 20px;
}

.minicard-container-on-sidebar-close {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  padding-right: 12px;
  margin-top: 20px;
}
.metric-value {
  /* Heading 03 (S) Bold */

  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  /* Neutral 100 */

  color: #002630de;
}

.metric-subheading {
  /* Heading 06 (S) Medium */

/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

display: flex;
align-items: center;
text-align: center;

color: rgba(76, 79, 106, 0.8);

}

h3 {
        text-align: left;
        font-size: 20px;
        text-align: center;
    }

@media screen and (max-width:1270px) {
  .title-heading-primary {
    width: 100%;
    height: 16px;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: rgba(76, 79, 106, 0.8);
  }

  .metric-value {
    /* Heading 03 (S) Bold */
  
    /* font-family: "Manrope"; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 120% */
  
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
  
    /* Neutral 100 */
  
    color: #002630de;
  }


}

    /* .card-text {
        text-align: center;
        font-size: 12px;
        margin-top: -8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: rgba(76, 79, 106, 0.8);
    }

    .card-col1-container {
        display: flex;
        justify-content: space-between;
    } */
