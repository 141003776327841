.negative-data-comparison {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px 16px 41px;
    background-color: #FFE9EA;
    text-align: center !important;
    color: #B91C1C !important;
}

.positive-data-comparison {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px 16px 41px;
    background: #ECFDF5;
    border-radius: 0px 0px 12px 0px;
    text-align: center !important;
    color: #047857 !important;
}

.default-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px 16px 41px;
    background: #f6f6fa;
    border-radius: 0px 0px 12px 0px;
    text-align: center !important;
}

.lp-table table tr td {
    text-align: center;
    border:1px solid #CCCCCC;
}
.lp-table table tr td:first-child {
    border-left: none;
}
.lp-table table tr td:last-child {
    border-right: none;
}

.lp-table table tr th {
    text-align: center;
    padding: 12px 40px;

}

.lp-table table tr th:first-child {
    text-align: start;
    padding: 12px 24px;

}

.lp-table table tbody tr td:first-child {
    text-align: start;
    padding: 12px 24px;


}

.colspan-haed-row .colspan-subhead {
    /* font-family: 'Inter'; */
    padding: 16px 40px;
    font-weight: 700;
    font-size: 12px;
    padding: 16px 40px;
    text-align: center;
}