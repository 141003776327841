.targeting-cation-aler-modal-connntainnr {
    width: 417px;
    height: 194px;
    background-color: white;
    display: flex;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */


    /* Neutral40 */

    color: #4F5366;

    padding: 0px 25px;
    padding-top: 25px;
    height: 129px;

    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.targeting-action-alert-backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aqua;
}

.targeting-action-alert-dialog-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnn-conntainer-targeting-alert-mmodal {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 8px 8px;
    height: 65px;
    align-items: center;


}

.alert-op-btn{
    width: 127px;
}

.alert-info{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4F5366;
    color: red;
    margin: 0 0 20px 0;
    text-align: center;
}