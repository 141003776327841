body {
	/* font-family: 'Inter'; */
	/* font-family: "Source Sans Pro"; 
	font-family: "Poppins";
	font-family: "Montserrat";
	font-family: "Noto Sans";
	font-family: "Lato";
	font-family: "Noto Sans";
	font-family: "IBM Plex Mono";
	font-family: "Rosario";
	font-family: "Hind Siliguri";
	font-family: "Poppins"; */
	font-family: "Roboto";
}

a {
	text-decoration: none;
}

.pie-chart-contaner-app {
	/* display: flex;
  	flex-direction: row;
  	justify-content: space-between;
  	align-items: center;
  	column-gap: 20px; */
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	padding-right: 10px;
}

.pie-chart-contaner-app {
	margin-top: 20px;
}

.w-100 {
	width: 100% !important;
}

.th-saperated {
	border-right: 1px solid white;
}

.table-border-light {
	border: 1px solid #CCE1E5 !important;
}

.p-t-none {
	padding-top: 0px !important;
}

.p-b-minus-10 {
	padding-top: -10px !important;
}

.m-t-none {
	margin-top: 0px !important;
}

.gap-10 {
	gap: 10px;
}

.layout-container {
	display: flex;
	flex-direction: row;
}

.section-main-body {
	min-width: 300px;
	width: 100%;
	margin-top: 40px;
	justify-content: center;
	align-items: center;
}

.app-head {
	background-color: blueviolet;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
}
body {
	/* font-family: 'Inter'; */
}

a {
	text-decoration: none;
}

.small-greyed-out-text {
	color: #6c757d;
    font-size: 12px;
}

.pie-chart-contaner-app {
	/* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px; */
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	padding-right: 10px;
}

.pie-chart-contaner-app {
	margin-top: 20px;
}

.w-100 {
	width: 100% !important;
}

.th-saperated {
	border-right: 1px solid white;
}

.table-border-light {
	border: 1px solid #CCE1E5 !important;
}

.p-t-none {
	padding-top: 0px !important;
}

.p-b-minus-10 {
	padding-top: -10px !important;
}

.m-t-none {
	margin-top: 0px !important;
}

.gap-10 {
	gap: 10px;
}

.layout-container {
	display: flex;
	flex-direction: row;
}

.section-main-body {
	/* margin: auto; */
	/* max-width: 1120px; */
	/* max-width: 1263px; */
	min-width: 300px;
	width: 100%;
	margin-top: 20px;
	justify-content: center;
	align-items: center;
}

.app-head {
	background-color: blueviolet;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.hide {
	display: none;
}

.primary-active-btn {
	display: flex;
	/* background-color: #4D4184; MS-96 selected btn color black type its reflected in whole project*/
	background-color: #07A9D2;
	border-radius: 24px;
	border: 0px;
	height: 32px;
	/* padding: 10px 24px; */
	justify-content: center;
	align-items: center;
	padding: 6px 20px;
	font-weight: 500;
	/* font-size: 14px; MS-96 font size of this button is 16px*/
	font-size: 14px;
	color: white;
	border: 1px solid #07A9D2;
	box-shadow: 0px 4px 20px 0px rgba(77, 65, 132, 0.20);
}

.primary-active-btn:hover {
	/* background: #07A9D2;
	border-radius: 24px; */
	background: #0087A9 !important;
    color: #fff !important;
    border: 1px solid #0087A9 !important;

}
.primary-active-btn.disabled, .primary-active-btn.disabled:hover {
	cursor: not-allowed;
}

.primary-inactive-btn {
	display: flex;
	border-radius: 24px;
	/* border: 1px solid #4D4184; */
	height: 32px;
	font-weight: 500;
	/* font-size: 14px; MS-96 font size of this button is 16px*/
	/* font-size: 12px;
	color: #1E2426; */
	justify-content: center;
	align-items: center;
	padding: 6px 20px;
	/* background: #FFFFFF;
	background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F1FCFF;
	border: 1px solid rgba(77, 65, 132, 0.3); */
	/* border-radius: 24px; */
	/* background: #fff !important; */
    color: #07A9D2 !important;
    /* border: 1px solid #07A9D2 !important; */
    font-size: 12px;

	/* Niharika changes 16-06-23 */
	background: #fff !important;
	border: 1px solid #999EA4 !important;
	border-radius: 24px !important;
	/* background: white !important; */
}

.primary-inactive-btn.disabled, .primary-inactive-btn.disabled:hover {
    color: #999 !important;
	background: #eee !important;
	cursor: not-allowed;
	border: 1px solid #999 !important;
}

.primary-inactive-btn:hover {
	/* background: #0087A9;
	color:#fff;
	border-radius: 24px; */
	/* background: #fff !important;
    color: #0087A9 !important;
    border: 1px solid #0087A9 !important; */

	/* Niharika changes 16-06-23 */
	background: #E8FAFF !important;
	color: #0087A9 !important;
	border: 1px solid #0087A9 !important;
	border-radius: 24px !important;
}

.secondary-active-btn {
	display: flex;
	/* background-color: #4D4184; MS-96 selected btn color black type its reflected in whole project*/
	background-color: #fff;
	border-radius: 24px;
	border: 0px;
	height: 32px;
	/* padding: 10px 24px; */
	justify-content: center;
	align-items: center;
	padding: 6px 20px;
	font-weight: 500;
	/* font-size: 14px; MS-96 font size of this button is 16px*/
	font-size: 14px;
	color: #07A9D2;
	border: 1px solid #07A9D2;
}

.secondary-active-btn:hover {
	/* background: #07A9D2;
	border-radius: 24px; */
	background: #0087A9 !important;
    color: #fff !important;
    border: 1px solid #0087A9 !important;

}

.secondary-inactive-btn {
	display: flex;
	/* background-image: linear-gradient(0deg, #F0EDFF, #F0EDFF, #F1FCFF); */
	border-radius: 24px;
	/* border: 1px solid #4D4184; */
	height: 32px;
	font-weight: 500;
	/* font-size: 14px; MS-96 font size of this button is 16px*/
	/* font-size: 12px;
	color: #1E2426; */
	justify-content: center;
	align-items: center;
	padding: 6px 20px;
	/* background: #FFFFFF;
	background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F1FCFF;
	border: 1px solid rgba(77, 65, 132, 0.3); */
	/* border-radius: 24px; */
	/* background: #fff !important; */
    color: #07A9D2 !important;
    /* border: 1px solid #07A9D2 !important; */
    font-size: 12px;

	/* Niharika changes 16-06-23 */
	background: #fff !important;
	border: 1px solid #999EA4 !important;
	border-radius: 24px !important;
	/* background: white !important; */
}

.secondary-inactive-btn.disabled, .secondary-inactive-btn.disabled:hover {
    color: #999 !important;
	background: #eee !important;
	border: 1px solid #999 !important;
}

.secondary-inactive-btn:hover {
	/* background: #0087A9;
	color:#fff;
	border-radius: 24px; */

	/* background: #fff !important;
    color: #0087A9 !important;
    border: 1px solid #0087A9 !important; */

	/* Niharika changes 16-06-23 */
	background: #E8FAFF !important;
	color: #0087A9 !important;
	border: 1px solid #0087A9 !important;
	border-radius: 24px !important;
}

.filter-applied{
	position: absolute;
    top: -6px;
    right: 4px;
    height: 12px;
    border: 2px solid white;
    width: 12px;
    background: #07A9D2;
    border-radius: 50%;
}

.gap-8 {
	gap: 8px;
}

/* Create Portfolio Button styling  */
.portfolio-label {
	position: relative;
	font-size: 12px;
	margin-bottom: 8px;
}

.portfolio-btn-container {
	position: absolute;
	right: 0;
	top: -13px;
}

#align-right {
	padding: 0px 0px;
}

.portfolio-border {
	border: 1px solid #dee2e6 !important;
	text-align: center !important;
	display: block !important;
}

.image-addetails {
	height: 150px;
	width: 170px;
}

.product {
	position: relative;
	border: 1px solid rgba(77, 65, 132, 0.7);
	border-radius: 7px;
	/* height: max-content; */
	height: auto;
}

/* MS-233 selected product highlight */
.selected-product {
	border: 2px solid #000;
	border-radius: 9px;

}

.product .image-container img {
	height: 150px;
	width: 153px;
	border-radius: 5px; /*MS-258 image box radius*/
}

.product .add-Icon {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}

.product .product_name {
	text-align: center;
	overflow-wrap: break-word;
}

/* use this class when you want to hide overflow horizontally */

.overflow-x-hidden {
	overflow-x: hidden;
}

/* .main-content-container-oncolapse-mode {
  margin-top: 98px;
  margin-left: 117px;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.5s;
  transition-timing-function: ease-in-out;
  margin-right: 20px;
}

.main-content-container-onopen-mode {
  margin-top: 98px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.5s;
  transition-timing-function: ease-in-out;
  margin-right: 20px;
} */

.create-campaign-outline {
	outline: none;
	color: #4D4184 !important;
}

.tesheight {}

.create-campaign-outline:focus {
	outline: 1px solid rgba(77, 65, 132, 0.5);
}

#automatic-daily-bd-inp {
	border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    width: 100%;
    height: 40px;
    padding: 0px 4px;
	padding-left: 10px;
}

#automatic-daily-bd-inp::placeholder {
	padding: 0%;
}

#manual-search::placeholder {
	padding: 0%;
}


.main-content-container-oncolapse-mode {
	margin-top: 98px;
	margin-left: 125px;
	/* margin-bottom: 20px; */
	display: flex;
	flex-direction: column;
	transition: margin-left 0.5s;
	transition-timing-function: ease-in-out;
	margin-right: 20px;
}

.main-content-container-onopen-mode {
	margin-top: 98px;

	margin-left: 260px;
	display: flex;
	flex-direction: column;
	transition: margin-left 0.5s;
	transition-timing-function: ease-in-out;
	margin-right: 20px;
}

.cursor-pointer-global {
	cursor: pointer;
}

.cstm__brdcrumb_wrapper {
	margin-top: 6.24px;
	padding-right: 10px;
}


::-webkit-scrollbar {
	/* background-color: #ffffff; */
	/* height: 20px; */
	height: 15px;
	width: 15px;

}

.pointer-disable-global {
	pointer-events: none;
	opacity: 0.5;
}

/* .lsit-table-scrollbar::-webkit-scrollbar-track:vertical {
	border: 1px solid #CCCCCC;
} */

.lsit-table-scrollbar::-webkit-scrollbar-track {
	background: #F8F8F8;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-shadow: outset 0px 7px 3px -3px rgba(0, 0, 0, 0.05);
	border: 4px rgb(255, 255, 255) solid;

}

::-webkit-scrollbar-thumb {
	background: #9DA2B9;
	border: 5px rgb(255, 255, 255) solid;
	height: 12px;
	width: 12px;
	border-radius: 20px;
}

/* Buttons */
::-webkit-scrollbar-button {
	display: block;
	height: 13px;

}

::-webkit-scrollbar-button:decrement:start {
	display: block;
	height: 13px;
	width: 16px;
	background: #F6F4FF;
	border-radius: 0px 0px 0px 7px;
	background-image: url(./assets/icons/left-arrow.svg);
	background-repeat: no-repeat;
	background-position: center center;

}

::-webkit-scrollbar-button:vertical:decrement:start {
	display: block;
	height: 13px;
	width: 16px;
	background: #F6F4FF;
	border-radius: 0px 0px 0px 7px;
	background-image: url(./assets/icons/arrow_up_icon.svg);
	background-repeat: no-repeat;
	background-position: center center;

}

::-webkit-scrollbar-button:vertical:increment:end {
	display: block;
	height: 12px;
	width: 12px;
	background: #F6F4FF;
	border-radius: 0px 0px 7px 0px;
	background-image: url(./assets/icons/down_arrow.svg);
	background-repeat: no-repeat;
	background-position: center center;
}

::-webkit-scrollbar-button:decrement:end {
	display: none;

}

::-webkit-scrollbar-button:increment:start {
	display: none;

}

::-webkit-scrollbar-button:increment:end {
	display: block;
	height: 13px;
	width: 16px;
	background: #F6F4FF;
	border-radius: 0px 0px 7px 0px;
	background-image: url(./assets/icons//right-arrow.svg);
	background-repeat: no-repeat;
	background-position: center center;

}


/* .active-filter-colmun {
	border: 2px solid #320F06;
	z-index: 44;

} */
.active-filter-higlighted-text {
	color: #fff;
}

/* .filter-icon-container-main {
	position: relative;
} */

.activated-min-max-filter {
	width: 9px;
	height: 9px;
	background: #FFAC40;
	position: absolute;
	border-radius: 50%;
	right: 9px;
	top: 9px;
}

.filter-icon-container-main {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	position: relative;
	/* background-color: red; */
	/* width: 100px;
    height: 100px; */
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: transparent;
}

.calender-inclasslist-container {
	background: #ffffff;
	/* height: 435px; MS-89 */
	height:auto;
	position: absolute;
	width: 892.4px;
	box-shadow: 0px 2px 10px rgb(77 65 132 / 20%);
	border-radius: 8px;
	background-color: white;
	z-index: 99;
	margin-top: 70px;
	right: 0;
	margin-right: 30px;
	padding-top: 20px;

}

.filter-icon-container-main:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #F6F6F7;
	cursor: pointer;
}

.search-input-width-for-edit-campaignlist-section {
	width: 457px;
}

.search-input-width-for-listing-pages {
	width: 100%;
}

.calender-margin-top-when-sidebar-open-for-campaignlist {
	height: auto;
	position: absolute;
	width: 892.4px;
	box-shadow: 0px 2px 10px rgb(77 65 132 / 20%);
	border-radius: 8px;
	background-color: white;
	z-index: 99;
	margin-top: 100px;
	right: 0;
	margin-right: 30px;
	padding-top: 20px;
}

/* MS-96 chart inside data */
span.legends-text {
    font-size: 12px;
}

/* MS-96 calander change when custom date selected , its applied to all*/
.date-btn-campaignlist {
    width: auto !important; 
	white-space: nowrap;
	min-width: 167px;
}

/* MS-96 calander text change 14 to 12 , its applied to all*/
.date-btn-text{
	font-size: 12px !important;
}

/* campaign-format-report redirect popup */
.redirect-popup .modal-body{padding: 32px;}
/* .modal-dialog.modal-dialog-centered .modal-content {
	padding:32px;
} */

/* MS-96 calender icon remove bg color */
.btn-calender-img-holder img.dropdown-img-for-select {
    opacity: 0;
}
button.btn-calender-img-holder{background-color: transparent;}
.redirect-popup .modal-body {
	padding: 32px;
}

/* MS-132 filter dropdown border */
.select-button-container button.img-container {
	border-radius: 0 8px 8px 0;
}

/* MS-132 placeholder color */
.create-campaign-outline::-webkit-input-placeholder {
	/* Edge */
	color: #dfdfdf !important;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #dfdfdf;
}

.create-campaign-outline::placeholder {
	color: #dfdfdf !important;
}

/* MS-132 input font size */
input,
select {
	font-size: 14px !important;
}


/* MS-132 point 9 table border */
.sp-product-targettig-outer-container .main-tableRb-body.position-relative {
	border: 1px solid #ccc;
}

.sp-product-targettig-outer-container .main-tableRb-body.position-relative .tableRb {
	border: none;
}

/* MS-132 point 10 point on label */
label {
	cursor: inherit !important;
}

.btnGroup label {
	cursor: pointer !important;
}

/* MS-132 action item hand icon */
.form-select {
	cursor: pointer;
}

/* Performance Overview design misalign */
.h-430 {
	height: 430px !important;
}

/* 220 highlighted rows`s font */
.main-targeting-table-body-container table tbody tr.highlight td { 
    font-weight: 600 !important;
    color:#4D4084!important;
}

/* 220 highlighted rows`s font */
.main-campaignlist-table-body-container, table tbody tr.highlight td { 
    font-weight: 600 !important;
    color:#4D4084!important;
}

/* 220 highlighted rows`s campaign count bg clr */
.highlight .campaign-count-circle
{
	background-color: #ccc;
}

/* 220 highlighted rows`s sub rows 2nd clm clr */
table tbody tr.highlight td.second-column-child {
	background-color: #C5DCFC ! important;
}

/* 220 highlighted rows`s sub rows */
table tbody tr.highlight td {
	background-color: #C5DCFC ! important;
}

.primary-btn, .btn-primary
{
	background: #07A9D2 !important;
	color:#fff !important;
	border: 1px solid #999EA4 ;
}
.primary-btn:hover, button.btn-greyish:hover, .btn-primary:hover
{
	background: #0087A9 !important;
	color:#fff !important;
	border: 1px solid #0087A9 !important;
}
button.btns.btn-greyish.btn.btn-outline-success:hover {
    background: #E8FAFF !important;
    color: #0087A9 !important;
    border: 1px solid #0087A9 !important;
}
.primary-outline-btn, button.btn-greyish
{
	background: #fff !important;
	color:#07A9D2 !important;
	border: 1px solid #999EA4  !important;
}
.primary-outline-btn:hover
{
	background: #fff !important;
	color:#0087A9 !important;
	border: 1px solid #0087A9 !important;
}
button.primary-outline-btn
{
	background: #fff !important;
	color:#07A9D2 !important;
	border: 1px solid #999EA4  !important;
	min-width: 137px;
    font-size: 14px;
}
button.primary-outline-btn:hover
{
	background: #E8FAFF !important;
	color:#0087A9 !important;
	border: 1px solid #0087A9 !important;
}
button.img-container{background: #fff !important;border-radius: 0 8px 8px 0 !important;}

/* MS-96 create campaign tab button */
.primary-btn.d-flex.nav-link.active
{
	background: #07A9D2 !important;
	color:#fff !important;
	border: 1px solid #999EA4 !important;
}
.primary-btn.d-flex.nav-link.active:hover
{
	background: #0087A9 !important;
	color:#fff !important;
	border: 1px solid #0087A9 !important;
}

.primary-btn.d-flex.nav-link:hover
{
	/* background: #0087A9 !important;
	color:#fff !important;
	border: 1px solid #0087A9; */
	background: #E8FAFF !important;
    color: #1E2426 !important;
    border: 1px solid #999EA4 !important;
}
.primary-btn.d-flex.nav-link
{
	/* background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F1FCFF !important;
    border: 1px solid rgba(77, 65, 132, 0.3) !important;
    border-radius: 24px;
	color: #1E2426 !important; */
	background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F1FCFF !important;
    color: #07A9D2 !important;
	border-radius: 24px;
    border: 1px solid #8C9196 !important;
}

/* MS-96 create-campaign table button Add / Remove */
.remove-all-btn {
    background: #FFF4F4 !important;
    color: #FD938D !important;
	cursor: not-allowed !important;
    font-size: 12px !important;
	height: 32px !important;
}
.remove-all-btn-dark {
    background: #FFF4F4 !important;
    color: #D82C0D !important;
	font-size: 12px !important;
	height: 32px !important;
}
.remove-all-btn-dark:hover
{
	background: #FEBCB9 !important;
	color: #670F03 !important;
}
.add-all-btn {
    background: #E8FAFF !important;
    color: #6FE2FF !important;
	cursor: not-allowed !important;
    font-size: 12px !important;
	height: 32px !important;
}
.add-all-btn-dark {
    background: #F3FCFF !important;
    color: #07A9D2 !important;
	box-shadow: none !important;
	font-size: 12px !important;
	height: 32px !important;
}
.add-all-btn-dark:hover
{
	background: #C0F2FF !important;
	color: #006680 !important;
}

.disable-pointer-events {
	pointer-events: none;
	opacity: 0.5;
}

.no-data-found-container {
	min-height: 350px;
}

/* MS-96 Meun icon in table create-campaign */
.menu-icon-table{cursor: pointer;}

input, select{
	font-size: 14px !important;
	font-weight: 400 !important;
}
::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #6f8c93 !important;
  
	opacity: 1 !important; /* Firefox */
  }

  .data-toggle-buttons button.primary-inactive-btn
  {
	background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F1FCFF !important;
    color: #07A9D2 !important;
    border: 1px solid #8C9196 !important
  }

  .data-toggle-buttons button.primary-inactive-btn:hover
  {
	background: #E8FAFF !important;
    color: #1E2426 !important;
    border: 1px solid #999EA4 !important;
  }
/* MS-89 when display resolution is small then scroll on calender  */
  .ml-10{
	margin-left: 10px;
  }
  .ml-auto
  {
	margin-left: auto;
  }

  .create-ad-group-input input::placeholder {
	padding: 2px !important;
  }

  input.input-placeholder::placeholder {
	padding: 2px !important;
  }

  input.input-placeholder {
	padding: 10px !important;
  }

  .create-ad-group-input input {
	padding: 10px !important;
  }

@media only screen and (max-width: 1400px) and (min-width: 1000px)  
{
	/* This CSS was causing the calendar component to have fixed max height and overflow effect */
	/* .rdrDateRangePickerWrapper
	{
		overflow-y: auto;
    	max-height: 380px;
	} */

}

.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset .seacrh-input-field:focus,
input.form-control:focus
{
	outline: none !important;
	box-shadow: none;
}
.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset .seacrh-input-field:focus-visible,
input.form-control:focus-visible
{
	outline: none !important;
	border: 1px solid #575959;
	box-shadow: none;
}
.line-graph-container .dropdown-btn, .line-graph-container .dropdown-btn:hover
{
	border-width: 2px !important;
}

.pencil-icon-hover {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    line-height: 40px;
    text-align: center;
}
.pencil-icon-hover:hover {
    background: #F6F6F7;
}

.subrow-pencil-icon-hover {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    line-height: 25px;
    text-align: center;
}
.subrow-pencil-icon-hover:hover {
    background: #F6F6F7;
}

.icon-hover {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    line-height: 25px;
    text-align: center;
}
.icon-hover:hover {
    background: #F6F6F7;
}

.selected-icon-hover {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    line-height: 25px;
    text-align: center;
}
.selected-icon-hover:hover {
    background: #F2F7FE;
}

.toast-cancel-button {
	position: absolute;
	top: -1px;
	right: -5px;
	background-color: #fff;
}

.toast-custom-className {
	/* min-width: 400px; */
	min-width: fit-content;
}

  ::-webkit-scrollbar-track {
	background: #FAFBFB !important;
  }
  
  ::-webkit-scrollbar-thumb{
	background: #8C9196 !important;
  }

	::-webkit-scrollbar-button{
		display: none !important;
	}

	.dropdown-element::-webkit-scrollbar-track {
		background: #FAFBFB !important;
	}
	
	.dropdown-element::-webkit-scrollbar-thumb {
		background: #8C9196 !important;
	}

	.defaultTableContainer table tbody tr td {
  /* border: 0.5px solid #cccccc; */
  padding: 8px 20px;
  text-align: left;
  border-right: 0.5px solid #cccccc;
  border-bottom: 0.5px solid #cccccc;
}
.defaultTableContainer table tr {
	border: none;
}
p.info-text {
    font-size: 14px; 
    color: #555; 
    background-color: #f9f9f9; 
    padding: 10px; 
    border-left: 4px solid #4caf50; 
    border-radius: 5px; 
    line-height: 1.6; 
    margin: 10px 0; 
	
}

.summary-table-row-fixed {
	position: sticky;
    top: 34px;
    background: white;
    z-index: 99;
    box-shadow: black;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 2px 2px 0px;
}