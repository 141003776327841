:root {
  --primary-color:#07A9D2
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aqua;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .btn-primary {
  background-color: #001b38 !important;
  border-color: #000000 !important;
  border-radius: 20px !important;
  color: #fff !important;
} */
