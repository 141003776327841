.dash-duration-bt {
  box-sizing: border-box;
  width: 150px;
  height: 40px;
  border: 1px solid #BDBDC3;
  border-radius: 24px;
  /* font-family: 'Inter'; */
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4C4F6A;

}



.horizontal-fixed {

  /* position: fixed !important; */
  top: 72px;
  background-color: white;
  width: 100%;
  z-index: 100;
  /* padding-top: 20px; */
  padding-left: 0% !important;



}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

.btn-text::after {
  background-image: url(../assets//icons/down_arrow.svg);
}

.sd-container {
  /* position: relative;
  float: left; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .sd {
  width: 150px;
  height: 40px;
  left: 434px;
  top: 108px;
  outline: none;
  border: 1px solid #BDBDC3;
  border-radius: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 3px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  color: #4C4F6A;
  padding-left: 0px;
} */

.date-btn-text {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* padding: 10px; */
  /* display: flex; */
  align-items: center;
  color: #4C4F6A;
}

/* .for-dateinput {
  padding-left: 13px;
} */

.dropdown-btn {
  width: max-content;
  height: 32px;
  border: 1px solid #BDBDC3;
  border-radius: 24px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #4C4F6A;
  padding-left: 17px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

}

.dropdown-btn:hover .regularclass {
  background: rgba(229, 225, 249, 0.5);
  border: none;
}

.dropdown-btn:hover {
  color: #1E2426 !important;
  background: #E8FAFF !important;
  border-radius: 24px !important;
  border:1px solid #999EA4 !important;
}



/* #dropdown-btn:hover {
  background: rgba(229, 225, 249, 0.5);
  border-radius: 24px
} */

#dropdown-btn {
  width: fit-content;
  height: 32px;
  border: 1px solid #BDBDC3;
  border-radius: 24px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #1E2426;
  padding-left: 17px;
  cursor: pointer;
  user-select: none;
  position: relative;

}

.dash-drodown-btn-container {
  display: flex;

  gap: 20px;
}

#cal-main-dash {
  position: relative;

}

#cal-dash-cut {
  position: absolute;
  left: 684px;
  top: 58px;
  font-size: 14px;
  color:
    #a8a6a6;
  z-index: 2000;
}

#dropdown .dropdown-content {
  position: absolute;
  height: 218px;
  top: 110%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  /* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
  color: #4F5366;
  border: 1px solid #BDBDC3;
  border-radius: 10px;
  z-index: 100;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
}



#dropdown .dropdown-content::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

#dropdown .dropdown-content::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px #fff;
}

#dropdown .dropdown-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #bdbdc3;
  border: 5px solid #8C9196;;
}


#dropdown .dropdown-content .dropdown-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid rgba(77, 65, 132, 0.15);

}

#dropdown .dropdown-content .dropdown-item:hover {
  background-color: #c2bebe22;
}

.dash-drodown-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dash-drodown-btn-container select {
  background-image: url(../assets/icons/down_arrow.svg);
  margin-right: 20px;
  outline: none;

}

.todayclass {
  margin-left: 30px;
}

.regularclass {
  margin-left: 10px;
  /* background-color: white; */
  background-color: transparent !important;
}


::placeholder {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  color: #4C4F6A;
}

/* #calender-img-icon :hover {
  background-color: rgba(229, 225, 249, 0.5);
} */

.sd img {
  background-color: white;
}

.open-button {
  position: absolute;
  top: 5px;
  right: 27px;
  width: 18px;
  height: 25px;
  background: #ffff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}

.btn-text {
  /* font-family: 'Inter'; */
  font-weight: 500;
  font-size: 14px;
  padding: 15px;
  display: flex;
  align-items: center;
  color: #4C4F6A;

}

.open-button button {
  border: none;
  background: transparent;
}

.dashboard-calender-main {
  box-sizing: border-box;

  /* position: absolute; */
  width: 852.4px;
  /* top: 60px; */
  /* margin-left: -280px; */
  /* z-index: 100; */
  background: #FFFFFF;
  /* box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
  border-radius: 8px; */
  margin-top: 40px;
  display: flex;
  justify-content: center;


}


.calender-indashboard-container{
  background: #FFFFFF;
  /* width: 852.4px; */
  /* height: 1000px; */
  z-index: 99;
  position: fixed;
  top: 150px;
  /* width: 852.4px;
  display: flex; */
  flex-direction: row;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
  border-radius: 8px;

}

.calender-close-icon {
  background-color: rgb(255, 255, 255);
  position: absolute;
  top:5px;
  right: 10px;
  /* display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: -20px; */
  height: 30px;
  cursor: pointer;
}

.dashboard-calender {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 852.4px;
  top: 150px; */
  /* margin-left: -400px; */
  z-index: 100;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
  border-radius: 8px;
}

.modal-body.dashboard-layout {
  padding: 0px !important;
  padding-left: 4px !important;
}

@media (max-width: 991px) {
  .dash-drodown-btn-container select {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACMSURBVHgBhY7NDYJAFITfZBsxHoxlrPGoJpZiA8Q1ag8cqQEO3PgpgSNcoBJgFi5kWcIkL+9l5pvkiVB3/X3Kjshou5U9AKTn41XqNiv98P8NSHQ6XCrVdHnHA/SNrzTDg+F8kjwIlTVZKnylJRxngbEenD8ZghBM3zN04FVhWZpCB97UQ/9eN44vGwHs8T1Vq+TkeAAAAABJRU5ErkJggg==);
    margin-right: 20px;
    outline: none;
    margin-bottom: 14px;
    border :'1px solid red'
  }

  .gear-icon-fordash-container {
    padding-bottom: 15px;
  }

  .main-content-container-oncolapse-mode {
    margin-top: 116px;
    margin-left: 39px;
    display: flex;
    flex-direction: column;
    transition: margin-left 1s;
    transition-timing-function: ease-in-out;
    overflow: auto;

  }

  .main-content-container-onopen-mode {
    margin-top: 116px;
    margin-left: 268px;
    display: flex;
    flex-direction: column;
    transition: margin-left 1s;
    transition-timing-function: ease-in-out;
    overflow: auto;
  }

}