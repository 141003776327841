.mainWrapper {
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    height: 70vh;
    width: 88vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.mainWrapperOpen {
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    height: 70vh;
    width: 80vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.informationWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
    align-items: center;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    padding: 64px;
    height: 100vh;
}

.errorHeading {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    width: 399px;
    text-transform: capitalize;
    width:100%;
}

.errorDescription {
    /* width: 400px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorDescription h6 {
    margin-bottom: 0.3rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.errorFollowupLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #000000;
}

#tryAgainButton {
    width: 146px;
    cursor: pointer;
    background-color: transparent;
    color: #4d4184;
    border: 1px solid #4d4184;
    border-radius: 24px;
    padding: 10px 24px;
    filter: drop-shadow(0px 4px 20px rgba(77, 65, 132, 0.2));
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    gap: 1px;
    justify-content: space-around;
    align-items: center;
}


.headerDiv {
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(170 170 170) 0px 1px 6px 0px;
    position: relative;
    display: flex;
    height: 56px;
    transition: 200ms background-color ease-in-out;
}

.navDiv {
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    border-right: 1px solid rgba(194, 204, 195, 0.76);
    border-left: 1px solid rgba(194, 204, 195, 0.84);
    box-shadow: rgb(194 204 195 / 12%) -1px 0px 1px 0px inset;
    padding-top: 10px;
    width: 242px;
}

.wrongImage {
    height:250px;
    width: auto;
}
.buttonWrapper {
    display:flex;
    gap:24px;
}

@media only screen and (max-width: 767px) {
    .logoBig {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .logoSmall {
        display: none;
    }
}