.pageFilterWrapper {
  margin-top: 12px;
  background-color: #fff;
  box-shadow: 0 0 0.3125rem rgba(23, 24, 24, 0.05),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.pageContent {
  padding: 20px;
  margin-top: 0px;
  background: #FAFBFB;
}

.pageContent .tabContent {
  width: 200px;
  height: auto;
  padding-bottom: 20px;
  top: 0;
  border: 1px solid #aeb4b9;
  border-radius: 8px;
}
.tabWrapper {
  position: sticky;
  top: 0px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.pageContent .tabWrapper {
  position: unset;
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.pageContent .tabHeading {
  border-radius: 8px 8px 0px 0px;
}
.label {
  display: flex;
  align-items: center;
  color: var(--Text-Default, #202223);
font-feature-settings: 'liga' off, 'clig' off;
font-family: Roboto;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.container {
  display: flex;
  padding: 8px;
  gap: 80px;
}

.innerContainer {
  display: flex;
  gap: 8px;
}

.runStopLoss {
  height: 120px;
  width: 100%;
}
.lineClass {
  display: flex;
  gap: 10px;
  align-items: baseline;
  margin-top: 10px;
}
.daysFrequency {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  gap: 8px;
  font-size: 14px;
  color: white;
  /* justify-content: center; */
  align-items: center;
}

.selectedCircle {
  padding: 4px 8px;
  margin-right: 4px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #c9cccf;
  background: #07a9d2;

  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.unselectedCircle {
  padding: 4px 8px;
  margin-right: 4px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #c9cccf;
  background: #fff;

  color: #212b36;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
