.edit-product-card-container {
    display: flex;
    flex-direction: column;

    display: flex;
    flex-direction: column;
    padding-top: 20px;

    display: flex;
    /* padding: 10px 20px; */
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #CCE1E6;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
    border-radius: 8px;
}


.edit-product-card-container .edit-product-card-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 20px;


}

.edit-product-card-container .edit-product-card-header .et-card-heading {
    display: flex;
    flex-direction: row;
}

.edit-product-card-container .edit-product-card-header .et-card-heading .setting-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    height: 40px;
    padding-left: 10px;
    background: #047857;
    opacity: 0.7;
    box-shadow: 0px 4px 20px rgba(110, 231, 183, 0.1);
    border-radius: 8px;
    text-align: center;
    color: #FFFFFF;
}

.edit-target-tabledata-td {
    justify-content: center;
    align-items: center;
    /* padding: 12px 7px; */
    height: 51.9px;
    border-radius: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.main-edit-targetting-table-body-container thead {
    border-radius: 8px;

    border-radius: 8px 8px 0px 0px;
    width: 100%;
    color: white;
    background-color: #FF8364;
    border-right: 1px solid #FF8364;
    border-left: 1px solid #FF8364;
    ;
}


.spends-icon {
    vertical-align: baseline;
}

.edit-product-card-container .edit-product-card-header .search-and-add-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.product-targetting-table .edit-product-main-table {
    /* height: 650px; */
    height: 79vh;
    width: 100%;
    overflow: auto;
}

.product-targetting-table .edit-product-main-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

/* .edit-product-main-table{
    height: 591px;
} */

.product-targetting-table .edit-product-main-table table thead tr th {
    /* padding: 12px 20px; */
    padding: 8px 20px;
    position: sticky;
    z-index: 10;
    top: 0;
    border-right: 1px solid #ffffff;
    text-align: left;


}

.diabled-add-prodcut-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 10px 24px; */
    /* gap: 10px; */

    width: 134px;
    height: 40px;

    /* Success 60 */

    background: #10B981;
    opacity: 0.3;
    box-shadow: 0px 4px 20px rgba(110, 231, 183, 0.1);
    border-radius: 8px;
    color: white;
}

.product-targetting-table .edit-product-main-table table tbody tr td {
    padding: 8px 20px;
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    text-align: left;
}

.product-targetting-table .edit-product-main-table table thead tr th:nth-child(1) {
    text-align: center;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 45;
    opacity: 100%;
}

.product-targetting-table .edit-product-main-table table thead tr th:nth-child(2) {
    position: sticky;
    left: 79px;
    top: 0;
    z-index: 45;
    opacity: 100%;
    border-right: 1px solid #e47518;
    box-shadow: 2px 1px 6px #c9705a91;
}


.smat-ads-not-editable-alert-container {
    box-sizing: border-box;


    width: 343.33px;
    height: 40px;
    left: 514px;
    top: 236.07px;

    background: #F3F1FC;
    /* Primary */

    border: 1px solid #4D4184;
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.smat-ads-not-editable-alert-text {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Primary */

    color: #4D4184;
}



.targetting-td-name {
    width: 308px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.serch-and-button-container {
    align-items: center;
    justify-content: space-between;

}

.add-keyword-btn {
    padding-left: 20px;

}


.product-targetting-table .edit-product-main-table table tbody tr .pt-text-left-align,
.product-targetting-table .edit-product-main-table table thead tr .pt-text-left-align
{
    text-align: start;

}

.product-targetting-table .edit-product-main-table table tbody tr .pt-end-align, 
.product-targetting-table .edit-product-main-table table thead tr .pt-end-align {
    text-align: end;

}
.product-targetting-table .edit-product-main-table table thead tr th:nth-child(2) {
    width: 248px;
    /* border-right: 2px solid #e47518;
    box-shadow: 2px 1px 6px #c9705a91; */
    /* background-color: #10B981; */


}


.main-product-td-second .main-table-product-container {
    display: flex;
    gap: 1rem;
    width: 400px;
  }

  .main-product-td-second
  .main-table-product-container
  .right-circle-image-container
  .image-product-table-second-td {
  height: 30px;
  width: 30px;
  background: #d9d9d9;
  border-radius: 10px;
}

.product-ads-description {
    text-align: start;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    color: #4c646a;
  }

  
.edit-second-column {
    position: sticky;
    left: 78px;
    background-color: white;
    height: auto;
    /* box-shadow-right: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    border-right: 1px solid #cccc;
    box-shadow: 2px 1px 6px #cccc;
}

.edit-first-column {
    position: sticky;
    left: 0;
    opacity: 100%;
    background-color: white;

}
@media only screen and (max-width: 820px) {

    .edit-product-card-container .edit-product-card-header {
        display: flex;
        flex-direction: column;

    }

    .edit-product-card-container .edit-product-card-header .search-and-add-btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
    }

    .edit-product-card-container .edit-product-card-header .serch-and-button-container {
        align-items: center;
        justify-content: space-between;

    }

}