.breadcrumbItem {
    box-sizing: border-box;
    width: max-content ! important;
    overflow: visible;
    word-break: break-word;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #212b36;
    padding: 2px 5px;
    border: 1px solid #dfe3e8 ! important;
    border-radius: 0 ! important;
    background-color: #fff;
    cursor:pointer;
    max-height: 24px;
    display: flex;
    align-items: center;
}

.breadcrumbDropdown {
    position: absolute;
    top:32px;
    left:0px;
    display: flex;
    flex-direction: column;
    border-radius: var(--p-border-radius-1);
    max-width: 25rem;
    width:max-content;
    padding: 8px;
    background:#FFF ;
    z-index:99;
    border: 1px solid #dfe3e8;
}
.breadcrumbItem.seperator {
    height:auto!important;
}
.breadcrumbItem.seperator:hover {
    background-color:#FFF !important;
    opacity: 0.9, import !important;
    border-color:#dfe3e8!important
}
.breadcrumbItem.seperator::after {
    display:none !important;
}
.breadcrumbDropdownWrapper .dropdown-item {
    font-size:12px;
    line-height: 18px;
    border-bottom: 1px solid #dfe3e8;
    padding:8px 16px;
}

.custom-breadcrumboptions-container .without-border {
    border-bottom: none !important;
}


.breadcrumbDropdownWrapper .dropdown-menu {
    padding: 0px;
    margin-top:4px;
}

.custom-breadcrumboptions-container {
    padding: 6px;
    background-color: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4F5366;
    border: 1px solid #BDBDC3;
    border-radius: 10px;
    z-index: 1000;
    left: 0;
    top: 33px;
    text-wrap: nowrap;
    width: inherit;
}

.custom-breadcrumboptions-container .dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    border-bottom: 1px solid rgba(77, 65, 132, 0.15);
}


.custom-bread-crumb-btn {
    display: flex;
    gap: 10px;
    font-size: 12px;
}