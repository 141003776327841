.csv-upload-logic-main-table {
    width: 100%;
    height: 90%;

}

.csv-upload-logic-main-table-thead {
    align-items: center;
    text-align: center;
    background: #FF8364;
    border-radius: 0px 8px 0px 0px;
    color: white;

}

.csv-upload-logic-main-table-tr {
    border-right: 1px solid white;
    padding: 10px;
}

.csv-upload-logic-main-table-inside-tr {
    border: 1px solid #9DA2B9;
    align-items: center;


}

.div-in-logic {
    display: flex;

    /* margin-left: 50px; */
}

.csv-upload-modal .filetr-modal-main-container .modal {
    background-color: #64ff69;
}

.main-modal-className-for-csv-upload .csv-upload-modal-two-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.csv-upload-table1 {
    width: 614.84px;
    margin-right: 20px;
    /* border-top: 5px solid red; */
    /* height: 357px; */
    border-radius: 20px;
    margin-bottom: 30px;
    /* height: 357px; */
}

.csv-upload-table2 {
    width: 614.84px;
    /* height: 357px; */
}

.main-modal-className-for-csv-upload {
    display: flex;
    justify-content: center;
    align-items: normal;
    /* width: 1312.47px; */
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    width: 450px;

}

.box_shedow_special {
    box-shadow: -4px 4px 9px #000;
    height: 1px;
    margin: 0 0 30px 0;
}

.csv-upload-modal .filetr-modal-main-container .csv-upload-modal-two-box-container .csv-upload-table1 table thead tr {
    background-color: rgb(51, 255, 0);
}

.csv-upload-modal .filetr-modal-main-container {
    background-color: #FF8364;
}

.csv-upload-dialog {
    display: flex;
    justify-content: center;
}


.csv-upload-logic-main-table th:first-child {
    border-radius: 8px 0 0 0;
}

.csv-upload-logic-main-table th:last-child {
    border-radius: 0 8px 0px 0px;
    /* border: 1px solid white; */

}

.modal-content.main-modal-className-for-csv-upload .modal-body {
    padding: 0px;
}

.limited_txt {
    margin-right: 10px;
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.upload_data_box {
    background: #F6F4FF;
    padding: 15px;
    border-radius: 5px;
    margin: 20px 0;
}

.csv-upload-modal-two-box-container {
    display: block !important;
    text-align: center;
}

.brd_upload_icn {
    border: 1px solid rgba(77, 65, 132, 0.3);
    border-radius: 35px;
    padding: 8px 24px 8px 16px;
    text-align: center;
    width: 92%;
    margin: auto;
}

.csv-upload-modal-two-box-container input[type="radio"] {
    height: 1.2rem;
    width: 1.2rem;
    color: red;
    accent-color: #4D4184;
    position: relative;
    top: 3px;
}

@media screen and (max-width:1200px) {
    .main-modal-className-for-csv-upload {
        display: flex;
        justify-content: center;
        /* width: 1000.47px; */
        height: fit-content;
    }

    .main-modal-className-for-csv-upload .csv-upload-modal-two-box-container {
        display: flex;
        flex-direction: column;

    }

}