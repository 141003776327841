/* .apexcharts-tooltip {
  background: #403d51 !important;
  color: white !important;
} */

.x-axis-label-underline {
  text-decoration: underline;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
}

/* Tooltip  */

.arrow_box-line-column {
  width: 195px;
  height: max-content;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #403d51 !important;
  padding: 10px;
}

.information-wrapper-with-legends {
  position: relative;
}

.legends-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 7px;
  top: 25%;
}

.legend-container-item {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.only-metrices-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.total-spends {
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 900;  
font-size: 11px;
color: #FFFFFF;   
text-align: start;
}

.prospect-spends {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #FFFFFF;
  text-align: start;
}

.retargeting-spends {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #FFFFFF;
  text-align: start;
}

.aggregated-roas {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 900;
  font-size: 11px;
  color: #FFFFFF;
  text-align: start;
}

.divided-roas {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #FFFFFF;
  text-align: start;
}

.legend-prospect {
  background-color: #A397DB;
}

.legend-retargeting {
  background-color: #8DEAFE;
}

.legend-roas {
  background-color: #97DB9A;
}

.chart-wrapper-div {
  position: relative;
  height: inherit;
  min-height: inherit; 
}