.btns {
  height: 40px;
  width: 173px;
  left: 617px;
  top: 1923px;
  border-radius: 24px;
  padding: 6px 12px 6px 12px;
}

.position {
  overflow-y: scroll;
  position: absolute;
  z-index: 3;
  width: 415px;
  height: 344px;
  padding: 20px;
}

.filter-heading {
  /* font-family: "Manrope"; */
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.filter-subs {
  /* font-family: "Inter"; */
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
}

.position::-webkit-scrollbar {
  display: none;
}

.portfolio-border {
  padding: 15px !important;
  border-top: none !important;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
}

.btns-grp {
  position: relative;
  bottom: 5px;
}

.position-check {
  position: absolute;
  bottom: -180%;
  left: 30%;
}

.position-check-2 {
  position: absolute;
  left: 31%;
  top: 17%;
  width: 70%;
}

.select-drop {
  position: relative;
}

.filter-dropdown .select-drop::before {
  content: "";
  display: block;
  width: 39px;
  height: 40px;
  /* background-color: rgba(219, 217, 230, 1); */
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 98;
}

.filter-dropdown select {
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 8px;
  height: 40px;
  z-index: 99;
  position: relative;
  background-color: transparent;
}

.btn-greyish {
  color: #4d4184 !important;
  border-color: #4d4184 !important;
}

.btn-greyish:hover {
  color: white !important;
  background-color: #4d4184 !important;
}

.input-group-symbol {
  background-color: transparent;
  color: #63677b;
}

.keyword-placeholder-container {
  position: relative;
}

.keyword-close {
  position: absolute;
  right: 30px;
  top: 13px;
  color: red;
}

.search-bar-container-client-switch .search-icon-container {
  position: absolute;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-left: 3px;
}

.search-bar-container-client-switch
  .search-input-container
  .search-input-field {
  width: 100%;
  padding-left: 40px;
  align-items: center;
  height: 40px;
  background: #ffffff;
  border: 1px solid #bdbdc4;
  font-size: 14px;
  line-height: 20px;
  border-radius: 24px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
}

.choosen-client-details .client-name {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.choosen-client-details .client-id-container .id-information span {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #212b36;
}

@media screen and (max-width: 640px) {
  .position {
    width: 300px;
    left: 50%;
    height: 250px;
  }
}
