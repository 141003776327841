.dynamic-filter-modal-container {
    position: absolute;
    z-index: 99;
}

.left-align {
    left: 0px;
}

.right-align {
    right: 0px;
}

.error-in-comp-filter {
    top: 14px;
    left: 65px;
    z-index: 40;
    font-size: 12px;
}

.error-border-red {
    border: 1px solid rgb(224, 70, 70) !important; 
}

.disable-modal-on-active:active {
    pointer-events: none;
}

.dynamic-filter-modal-btn-container {
    padding-top: 1rem;
}