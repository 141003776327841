.campaign-list-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

/* campaign list horizontal stack CSS  */
.campaign-list-container .section-heading .vstack {
  flex: 0;

}

.cl-custom-pagination a {
  box-shadow: none !important;
  transition: none !important;
}

.cl-custom-pagination a:focus {
  background:none;
}

.cl-custom-pagination a:hover {
  background-color:#e9ecef;
}

.cl-bottom-margin {
  margin-bottom: 16px;
}

.cl-breadcrumb-wrapper {
  padding-right: 6px;
}

/* draft Capsuel CSS */
.campaign-list-container .draft-text-container {
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
  /* width: 74px; */
  height: 32px;
  background: linear-gradient(0deg, #f0edff, #f0edff), #f1fcff;
  border-radius: 24px;
}

.date-btn-campaignlist {
  box-sizing: border-box;
  width: 167px;
  height: 40px;
  border: 1px solid #8C9196;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: relative;
  align-items: center;
}

.date-btn-campaignlist:hover {
  /* box-sizing: border-box;
  width: 167px;
  height: 40px;
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: relative;
  align-items: center; */

  /* Niharika changes 16-06-23 */
  background: #E8FAFF;
  border-radius: 8px;
  color: #1E2426;
}

.campaign-name-input-disable {
  border: none;
  border: none;
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; */
  background-color: white;
  resize: none;

  /* height: auto; */
}

.pencil-icon-in-row-container {
  /* padding-left: 10px; */
  display: flex;
  align-items: center;
}

.plus-icon-for-camapign {
  display: flex;
  align-items: center;
}

.campaign-name-container {
  /* width: 300px; */
}

.campaign-category-column {
  min-width: 300px;
}

.campaign-disabled-adset-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  width: 50px;
  height: 19px;
  background: #e3defe;
  opacity: 0.3;
  border-radius: 4px;
}

.cl-ennable-paused-btns-height {
  height: 32px;
  margin-right: 16px;
}

.padding-loader {
  margin-right: 12px;
}

/* draft text CSS */
.campaign-list-container .draft-text-container .draft-text {
  /* font-weight: 500;
  font-size: 14px;
  line-height: 20px; */
  color: #4d4184;
}

/* dropdown buttons container CSS this will wrap overflow  */
.campaign-list-container .cl-config-row .cl-config-btns-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
}

.cl-config-btns-container .hstack {
  /* margin-left: 12px; */
  align-items: flex-end;
}

/* .cl-config-btns-container .filter-option-container .camp-type-btn{
    margin-left: 12px;
} */

/* search bar CSS */
.campaign-list-container .cl-config-row .cl-config-btns-container .cl-config-btns-container .search-bar-container {
  display: flex;
  flex-direction: row;
}

/* .cl-config-row{
    margin-left: 12px;
} */
.btn-container-campaignlist {
  margin-left: 12px;
  margin-top: 10px;
}

.adset-edit-pencil-icon {
  cursor: pointer;
}

.pasue-resume-adset {
  cursor: pointer;
}

.edit-campaign-name-pencil {
  cursor: pointer;
}

.search-bar-container .search-icon-container {
  position: absolute;
  display: flex;
  justify-content: a;
  align-items: center;
  padding-top: 11px;
  display: flex;
  justify-content: a;
  align-items: center;
  padding-top: 11px;
  padding: 13px;
}

.search-bar-container .search-icon-container .search-icon {
  color: #4d4184;
}

.search-bar-container .seacrh-input-field {
  width: 230px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 8px;
  /* padding-left: 25px;
  font-size: 10px; */
  padding-left: 39px;
  font-size: 12px;
}

.search-bar-container input::placeholder {
  padding-left: 5px;
}

/* customised CSS for table  */
.budget-td-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-left: 4px; */
  /* gap: 33px; */
  width: 130px;
  height: 32px;
  background: #E8FAFF;
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 4px;
}

.budget-td-container-placement {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* padding-left: 4px; */
  /* gap: 33px; */
  /* width: 130px; */
  height: 32px;
  background: #E8FAFF;
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 5px;
  width: fit-content;
}

.adjust-bid-input {
  /* border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; */
  border-radius: 4px;
}

/* budget text CSS */
.budget-no {
  /* font-weight: 500;
  font-size: 14px;
  line-height: 20px; */
  color: #000000;
}

/* budget icon container CSS */
.edit-budget-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  /* background: #d4d0e6; */
  border-radius: 100%;
  cursor: pointer;
}

.edit-budget-icon-container:hover{
  background-color: #EDEEEF;
}
.bid-startegy-op-container select {
  /* background-image: url(../../assets/icons/downArrowForSplitButton.png); */
  outline: none;
  border: none;
  width: 104px;
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px; */
  /* line-height: 20px; */
  border: none;
  color: #4c646a;
  cursor: pointer;
}

.bid-startegy-op {
  outline: none;
  outline: none;
  border: none;
  width: 104px;
}

.choose-portfolio-forcampaignlist .form-select {
  background-size: 39px;
  margin-right: 0px;
}

.choose-portfolio-forcampaignlist select {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFkSURBVHgB7dixSsNQFIDhc4NUrBlEZ8EuQnAxD6BQFxdBVydfwTfQvIFubuLkquDiIAoOCh0ScJIMVkQ3i0PaYkJz9FxRQ7BYckJ7hvsvTZo7fNwkJLkqvH9BGH2BUhgkvdhznFoze0AJAf6mYC9qv3uuW3ujXQukhbBtVyuXvv8wRbvygDq1aE+O7+gtcac4W9qrC53Br1CpDdFAS1nrsmcQYE40kDJAbgbIzQC5GSA3FrDbieEuePx33CBj+sUCNm5DODy4gPMzv++Y46NrPab1GkGRxoDR8soCPD+1foCray7kcY2bUP8/PWNDkVhAanNrSf/mkVlcHj5UIJVH0uksA0eVAqT+mkkujioNSBGSrrWJakVfn2Uk+5sEzJOEnwFyM0BuBshN+spCIHttBlA2MEljTywQMd2n9WqhQAza3WSXtsQBaeaiTlz/XkQv9X2waJ93axMwPVWIJ/PO7FX22Ae+zYOdRl7jtgAAAABJRU5ErkJggg==);
  background-position-x: 100%;
  /* font-weight: 500;
  font-size: 14px; */
  display: flex;
  padding-left: 10px;
  /* padding:  10px; */
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500; */
  /* background-color: #3c12f7; */
  width: 167px;
  height: 40px;
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 8px;
  font-weight: 500;
  /* font-size: 14px; */
  display: flex;
  align-items: center;
  color: #4c646a;
  outline: none;
  /* font-family: "Inter";
  font-style: normal; */
}

/* this will add customised dropdown arrow img to select tag  */
.cselect {
  box-sizing: border-box;
  background-image: url(../../assets/icons/downArrowForSplitButton.svg);
  background-position-x: 100%;
  padding-left: 10px;
  width: 167px;
  height: 40px;
  left: 1233px;
  top: 180px;
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 8px;
  /* font-weight: 500; */
  /* font-size: 14px; */
  display: flex;
  align-items: center;
  color: #4c646a;
  outline: none;
  /* font-family: "Inter";
  font-style: normal; */
}

/* css for dropdown options customisation */
.filter-options {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  padding-bottom: 4px;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  color: #4f5366;
}


/* css for date selection input  */
.date-container input {
  width: 167px;
  height: 40px;
  left: 1233px;
  top: 180px;
  border: 1px solid #8C9196;
  border-radius: 8px;
  outline: none;
  display: flex;
}

.select-div {
  /* background-color: #3c12f7; */
  width: 166px;
  height: 40px;
  /* left: 700px;
    top: 180px; */

  border: 1px solid #8C9196;
  border-radius: 8px;
  background-color: white;
}

.img-container {
  display: flex;
  justify-content: flex-end;
  border-radius:12px ! important;
}

.dropdown-img-for-select {
  pointer-events: auto;
  height: 38px;
  border-radius: 0px 3px 3px 0px;
  width: 100%;
  object-fit: contain;
}

.btn-calender-img-holder {
  cursor: pointer;
  border-radius: 8px;
  margin-left: 5px;
}

.select-option-container {
  position: absolute;
  width: 100%;
  min-height: auto;
  max-height: 202px;
  /* height: 202px; */
  z-index: 200;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
  border-radius: 8px;
  padding: 2px 2px 0px 0px;
  /* overflow: scroll; */ 
  overflow-y: scroll;
  top: 40px;

}

.select-opt {
  height: 40px;
  background-color: white;
  color: #63677b;
  /* Neutral50 */
  width: 100%;
  color: #63677b;
  border-bottom: 1px solid rgba(77, 65, 132, 0.15);

  padding: 5px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  text-align: left;
}

.selected-opt-show-btn {
  width: 100%;
  background-color: white;

  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; */
  /* identical to box height, or 143% */
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Neutral40 */

  color: #4f5366;
  margin-left: 10px;

  margin-left: 10px;
  font-size: 12px;

  color: #303346;
  font-weight: 400 !important;
}

.select-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 180px;
}

.date-input-value {
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; */
  display: flex;
  align-items: center;
  text-align: start;
  color: #4f5366;
  padding-left: 10px;
}

.bid-strategy-op-container {
  width: 130px;
  border: none;
  padding: 10px;
  /* font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px; */
  /* line-height: 20px; */
  color: #4c646a;
}

option {
  background-color: #ffffff;
  color: #4c646a;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.3);
  border-radius: 8px;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody tr .campaign-details {
  background: rgba(243, 241, 252, 0.3);
}

.enabled-text {
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #4d4184;
  display: flex;
  flex-direction: row;
}

/* CSS for when campaign is enabled */
.enabled-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 19px;
  background: #e3defe;
  border-radius: 4px;
}

/* CSS for when campaign is diabled */
.disable-text-container {
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  position: absolute;
  width: 50px;
  height: 19px;
  background: #e3defe;
  opacity: 0.3;
  border-radius: 4px;
}

.edit-adgroup-text-row {
  position: sticky;
  right: 0;
  left: 0;
  background-color: #F2F7FE;
  color: white;
}
.text-center.cursor-pointer-global
{
  border: 1px solid #999EA4;
    border-radius: 30px;
    color: #07A9D2;
    font-weight: 600;
    padding: 6px;
}
tr.edit-adgroup-text-row td{border-right:none !important;}
.campaign-list-main-table .edit-adgroup-text-row .edit-adgroup-td {
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px; */
  /* identical to box height, or 143% */
  align-items: center;
  text-align: center;
  color: #ffffff;

}
.cursor-pointer-global b{
  font-size: 15px;
    margin-right: 7px;
}

.sub-adset-container {
  display: flex;
  justify-content: space-between;
}

/* edit text CSS */
.edit-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #4d4184;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.input-check-circle {
  align-items: center;
}

.cancel-check-circle {
  height: 18px;
  /* margin-top: -8px; */
  margin-left: 5px;
}

/* .campaign-list-main-table .campaign-details .blank-first-adset-td-column {
  background-color: white;
  border: hidden;
  position: sticky;
  left: 0;
} */

.campaign-list-main-table .edit-adgroup-text-row .blank-first-adset-td-column {
  background-color: white;
  border: hidden;
  position: sticky;
  left: 0;
}

.adset-name-input {
  border: none;
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px; */
  /* line-height: 20px; */
}

.classlist-switch-btn .form-switch {
  display: flex;
  width: 100%;
  justify-content: center;
}

.campaign-list-main-table .main-table-body-container table {
  width: 100%;
  overflow: visible;
  overflow-x: scroll;
  height: 551px;

}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody tr td {
  /* border: 0.5px solid #cccccc; */
  padding: 8px 20px;
  text-align: left;
  border-right: 0.5px solid #cccccc;
  border-bottom: 0.5px solid #cccccc;
}

.campaign-list-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}



/* this will make headers first column first data cell sticky */
.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tr th:nth-child(1) {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 45;
  opacity: 100%;
  border-right: 1px solid #FFFFFF;
}

/* this will make header of table sticky  */
.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tr .campaig-list-head {
  padding: 8px 20px;
  position: sticky;
  top: 0;
  z-index: 40;
  opacity: 100%;
  border-right: 1px solid #FFFFFF;
  text-align: left;
}


/* background-image:  */

/* Shadows */
/* linear-gradient(to right, #ff8464, #e8af93), */

/* Shadow covers */
/* linear-gradient(to right, rgba(119, 115, 115, 0.25), rgba(232, 85, 85, 0)),
    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0)); */


/* this will make first 1 column of table fixed & non-scrollable */
/* .campaign-list-main-table table tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 35;
    opacity: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
} */

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody .second-column {
  /* position: -webkit-sticky; Safari */
  position: sticky;
  /* left: 135px; */
  left: 77px;
  z-index: 21;
  /* width: 248px; */
  /* height: 52px; */
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  height: auto;
  /* display: flex;
.campaign-list-table-data
  .campaign-list-main-table
  .main-campaignlist-table-body-container
  table
  tbody
  .second-column {
  position: sticky;
  left: 135px;
  z-index: 21;
  width: 248px;
  /* height: 52px; */
  background-color: white;
  height: auto;
  border-right: 1px solid #cccc;
  box-shadow: 2px 1px 6px #cccc;
  /* display: flex;
    align-items: center; */
}

.cl-name-text {
  resize: none;
  text-align: start;
  width: 200px;
  display: flex;
  /* overflow-wrap: break-word;
   white-space:pre-wrap ; */
  display: inline-block;
  word-break: break-word;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container {
  /* height: 519px;
  overflow-y: scroll; */
  /* height: 725px; */
  /* overflow-y: auto; */
  margin-bottom: 10px;
  max-height: 680px;
  /* margin-bottom: 20px; */
}

.disable-toggle-btn-text {
  /* Heading 06 Medium */
  /* identical to box height, or 143% */
  color: #4c646a;
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; */
  /* identical to box height, or 143% */

  color: #4c646a;
}

.enable-toggle-btn-text {

  /* identical to box height, or 143% */
  color: #000000;
  /* font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; */
  /* identical to box height, or 143% */

  color: #000000;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody .first-column-sw-btn {
  position: sticky;
  left: 0;
  z-index: 35;
  opacity: 100%;
  background-color: #ffffff;
  /* display: flex;
    justify-content: center; */
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tr {
  border: none;
}



/* this will make second column sticky in subtable */
.campaign-details-data:nth-child(2) {
  position: sticky;
  left: 77px;
  background: #F2F7FE;
  opacity: 100%;
  border-right: 1px solid #cccc;
  box-shadow: 2px 1px 6px #cccc;
}

/* this will make headers second column first data cell sticky */
.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tr th:nth-child(2) {
  position: sticky;
  left: 77px;
  top: 0;
  z-index: 45;
  opacity: 100%;
  border-right: 1px solid #e47518;
  box-shadow: 2px 1px 6px #c9705a91;
  padding-left: 20px;

}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tr th:nth-child(6) {
  min-width: 160px;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tr th:nth-child(9) {
  min-width: 120px;
}

.campaign-details td:nth-child(1) {
  position: sticky;
  left: 0px;
  background: #F2F7FE;
}


/* .campaign-details-data:nth-child(1) {
    position: sticky;
    left: 0px;
    background: rgba(243, 241, 252, 100%);
} */

.main-calender-class {
  box-sizing: border-box;
  position: absolute;
  width: 852.4px;
  left: 300.6px;
  top: 340.93px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
  border-radius: 8px;
  z-index: 100;
  /* border: 3px solid red; */
}

.add-set-name-container {
  display: flex;
  align-items: center;
  height: 28px;
  background: #ffffff;
  border: 1px solid rgba(77, 65, 132, 0.5);
  border-radius: 4px;
  outline: none;
  border: none;
  width: 100%;
  justify-content: space-between;

  border: 1px solid #d3d3d3;
}

.add-set-name-container input {
  border: none;
  outline: none;
  width: 80%;
}

.input-adset-name {
  border: none;
}

.hoverGreen {
  cursor: pointer;
  /* padding-right: 5px; */
}

/* This is to position elements  */

.main-display-campaign {
  position: relative;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
}

.edit-penncil {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* background-color: #4D4184; */
  padding-left: 15px;
}

.main-edit-icon {
  /* position: absolute;
  right: 0px; */
  /* margin: 8px; */
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.budget-value {
  width: 98px;
  background: #E8FAFF;
  border: 1px solid rgba(77, 65, 132, 0.2);
  border-radius: 4px;
  outline: none;
  border: none;
  padding-left: 10px;
}

.budget-value-edit {
  width: 98px;
  border: 1px solid black;
  width: 98px;
  height: 100%;
  padding-left: 10px;
  /* width: 98px; */
  /* background: #F6F4FF; */
  /* border: 1px solid rgba(77, 65, 132, 0.2); */
  /* border-radius: 4px;
    border: 1px ; */
}

.text-now {
  margin-left: 34px;
}

.adset-table-footer {
  width: 100%;
}

.calender-icon-for-campaignlist {
  position: absolute;
  margin-left: -31px;
  margin-top: 8px;
}

.date-btn-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* width: 125px; */
  margin-left: 10px;
}



.calender-close-icon-campaignlist {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 20px;
  margin-right: 8px;
  /* margin-top: 8px; */
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tr .cl-end-align {
  text-align: end;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody tr .text-right-alignn {
  text-align: right;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.seacrh-input-container input::placeholder {
  /* font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px; */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #A7A7A7;
  font-size: 11px;


}




.create-new-adset-text {
  background-color: #418443;
  width: 100%;
  position: absolute;
  right: 50%;
  left: 50%;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody tr .child-font {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody .campaign-details {
  /* background-color: rgba(243, 241, 252, 0.3); */
  background-color: #F2F7FE;
}

.captital-first-letter {
  text-transform: capitalize;
}

.line-column-graph-main {
  min-width: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 10px;
}

.line-column-graph-container {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
  height: min-content;
  max-height: 420px;
}

.line-column-graph-container-on-sidebar-open {
  height: min-content;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
  max-height: 395px;
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: orange;
}

.line-column-graph-foter {
  display: flex;
  justify-content: space-between;
  margin: auto 30px;
}

.settings-icon {
  height: 25px;
  width: 25px;
  margin-top: 2px;
}

.dates-description-graph {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  /* font-size: 14px; changes in MS-96 its display in charts*/
  font-size: 12px;
  line-height: 20px;
  /* or 143% */
  /* Neutral 90 */
  color: #132A30;
  opacity: 0.8;
  margin-top: .8rem;
}

.line-column-graph-legends {
  display: flex;
  justify-content: space-between;
  margin: auto 30px;
}

.line-column-graph-title {

  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */


  color: #002630;

  opacity: 0.8;
}

.dotted-line {
  position: relative;
  bottom: 5px;
  color: #767676;
}

.adgroup-clickable-text-color {
  color: #005AA4;
}


.mt-filte-icon-container-align-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.position-relative-container {
  height: 290px;
  max-height: 300px;
  position: relative !important;
}

.position-relative-container-master {
  height: 330px;
  max-height: 350px;
  position: relative !important;
}

.position-relative-container {
  /* height: 290px;
  max-height: 300px; */
  height: 320px;
  max-height: 320px;
  position: relative !important;
}

.position-relative-container-master {
  height: 330px;
  max-height: 350px;
  position: relative !important;
}

.margin-top-cl {
  margin-top: 4px;
}
.ndf-text-container{
  position: absolute;
  left: 50%;
  border: 50%;
  margin-top: 100px;
}
.cl-config-row {
  display: flex;
  flex-direction: row;
  position: relative;
}
.cl-filter-buttons-main-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: end;
}

td.second-column.name-adjust
{
  width:360px;
}
/* td.second-column.name-adjust .cl-name-text{width:auto;} */
