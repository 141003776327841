.dropdown-toggle {
    background-color: #FFFFFF;
    border-radius: 24px;
    color: rgba(76, 79, 106, 1);
    width: 144px;
    height: 40px;
    font-size: 14px;
    color: rgba(76, 79, 106, 1);

    /* color:
        rgba(76, 79, 106, 1) */
    /* color: #4C4F6A; */
}

.dropdown-toggle::after {

    margin-left: 2.2550000000000026em;

}

.dropdown-btn {
    background-color: white;
    border-radius: 24px;
    color: "#4C4F6A";
    --bs-btn-hover-color: #6c757d;
    --bs-btn-hover-bg: #ffffff;
    --bs-btn-active-color: #rgb(0, 0, 0);
    ;
    --bs-btn-active-bg: #FFFF;
}

/* .dropdown-toggle:hover {
    background-color:
} */