.product-sum-btn-container{
    margin-top: 20px;

}

.product-sum-table table tr td {
    text-align: center;
    padding: 16px 24px;
    border:1px solid #CCCCCC;
}
.product-sum-table table tr td :first-child {
    border-left: none;
}
.product-sum-table table tr td :last-child {
    border-right: none;
}

.product-sum-table table tr th {
    text-align: center;
    padding: 12px ;
}

.zero-spend-product-image {
    height: 20px;
}

@media screen and (max-width:1480px) {
    .product-sum-table table tr th:nth-child(3) {
        min-width: 200px;
        padding: 4px;
    }
    .product-sum-table table tr th:nth-child(4) {
        min-width: 200px;
        padding: 4px;
    }
    .product-sum-table table tr th:nth-child(5) {
        min-width: 200px;
        padding: 4px;
    }
}
    