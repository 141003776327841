/* searchbar main container */
.search-bar-container-subclass .search-bar-container-for-edit-campaign {
    display: flex;
    flex-direction: row;
    /* width: 457px; */
    height: 40px;
}

/* search bar icon container */
.search-bar-container-subclass .search-bar-container-for-edit-campaign .search-icon-container {
    position: absolute;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    padding: 13px;
}

/* search icon  */
.search-bar-container-subclass .search-bar-container-for-edit-campaign .search-icon-container .search-icon {
    color: #4D4184;
}

.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-field-open {
    width: 457px;
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 24px;
    color: #A7A7A7;
    font-size: 14px;
    line-height: 20px;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.seacrh-input-field-open {
    width: 350px;
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 24px;
    color: #A7A7A7;
    font-size: 14px;
    line-height: 20px;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

/* search input field */
.search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset .seacrh-input-field {
    width: 457px;
    padding-left: 26px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 8px;
    color: #A7A7A7;
    font-size: 14px;
    line-height: 20px;

}


/* Input Field for Keywords Page  */

.seacrh-input-field-keywords {
    width: 460px;
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 8px;
    color: #A7A7A7;
    font-size: 14px;
    line-height: 20px;
    border-radius: 24px;
}
.create-new-adset-modal .seacrh-input-field-keywords{
    width: 432px;
}
.add-targeting .seacrh-input-field-keywords{
    width: 432px;
}
.seacrh-input-field-keywords::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   padding: 0% !important;
  }
  


.seacrh-input-field-keywords-open {
    width: 376px;
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 8px;
    color: #A7A7A7;
    font-size: 14px;
    line-height: 20px;
    border-radius: 24px;
}



/* Input  Field for Asin's Page  */

.seacrh-input-field-asins {
    width: 472px;
    padding-left: 40px;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 8px;
    color: #A7A7A7;
    font-size: 14px;
    line-height: 20px;
    border-radius: 24px;
}


.keywords-searchbar-container {
    position: relative;
   
}

.keywords-search-dropdown-container {
    position: absolute;
    z-index: 3;
    background: white;
    padding:10px 15px;
    overflow-y: scroll;
    min-height: 70px;
    max-height: 368px;
    width: 450px;
    margin-top: 10px;
}


.keywords-search-dropdown-container::-webkit-scrollbar {
    width: .5em;
}

.keywords-search-dropdown-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid white;
    border-radius: 8px;
    border: transparent;
}

.keyword-container {
    border-bottom: 1px solid rgba(77, 65, 132, 0.15);;
    padding: 10px 0px;
    width:364px;
    cursor: pointer;
}

.keyword-container:hover {
    background-color:  rgba(77, 65, 132, 0.05);
}
.filter-dropdown .select-drop::before
{
    background: none !important;
    content: "" !important;
}
.filter-dropdown .select__value-container
{
    position: relative;
    z-index: 99;
    padding-left: 31px;
}
.filter-dropdown .select__indicators
{
    position: absolute;
    right:0;
}
.filter-dropdown .select__indicators .select__indicator-separator{background: none;}

.filter-dropdown .css-13cymwt-control
{
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
}
.dropdown-indicator{
    padding: 5px 10px 0 0;
}
.dropdown-indicator img.search-box-pop
{
    z-index: 1000;
    position: absolute;
    left: -433px;
    top: 9px;
}

@media screen and (max-width:1440px) {
    .seacrh-input-field-asins {
        width: 420px;
    }
}

@media screen and (max-width:1400px) {
    .seacrh-input-field-keywords {
        width:420px !important;
    }

    .seacrh-input-field-asins {
        width: 380px;
    }
    

    .seacrh-input-field-keywords-open {
        width:364px !important;
    }
}
@media screen and (max-width:1330px) {
    .seacrh-input-field-keywords {
        width:390px !important;
    }

    .seacrh-input-field-keywords-open {
        width:330px !important;
    }

    
    .seacrh-input-field-asins {
        width: 350px;
    }
}
@media screen and (max-width:1280px) {
    .seacrh-input-field-keywords {
        width:370px !important;
    }

    .seacrh-input-field-keywords-open {
        width:300px !important;
    }

    .seacrh-input-field-asins {
        width: 300px;
    } 
}


@media only screen and (max-width: 820px) {
    .search-bar-container2 .seacrh-input-field {
        width: 300px;
        padding-left: 40px;
        align-items: center;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #BDBDC4;
        border-radius: 8px;
        color: #A7A7A7;
        font-size: 14px;
        line-height: 20px;
        
    }
    .edit-targetting-card-container .edit-targetting-card-header {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .search-bar-container-subclass .search-bar-container-for-edit-campaign .seacrh-input-container-for-edit-adset .seacrh-input-field {
        width: 200px;
        padding-left: 40px;
        align-items: center;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #BDBDC4;
        border-radius: 8px;
        color: #A7A7A7;
        font-size: 14px;
        line-height: 20px;
    }
}