.imageBox {
  width: 260px;
  height: 260px;
  background-color: rgb(249, 250, 251);
  border-style: dashed;
  border-width: 2px;
  border-color: rgb(196, 205, 213);
  margin: 20px;
}

.openModal {
  color: rgb(0, 122, 206);
  cursor: pointer;
  text-align: center;
  font-size: 18px;
}

.formButton {
  float: right;
  margin: 5px;
  padding: 0px;
  cursor: default;
  text-align: center;
  box-sizing: border-box;
  width: 88px;
  height: 36px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
}

.save {
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.popupModal {
  min-width: 1000px;
}

.dropdownList {
  height: 100%;
  width: 100%;
  min-height: 56px;
  border: 1px solid rgb(196, 205, 213);
  background: -webkit-linear-gradient(
    top,
    rgb(255, 255, 255) 0%,
    rgb(249, 250, 251) 100%
  );
  color: rgb(37, 37, 37);
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
  box-shadow: rgb(22 28 37 / 0%) 0px 1px 0px 0px;
  text-transform: capitalize;
}

.dataFiltersDiv {
  position: relative;
  display: flex;
  min-height: 36px;
  flex-wrap: wrap;
  margin: 20px;
}

.searchIconSpan {
  display: flex;
  width: 37px;
  border-width: 1px;
  border-style: solid none solid solid;
  border-color: rgb(196, 205, 213) rgb(196, 205, 213) rgb(196, 205, 213)
    rgb(189, 189, 189);
}

.searchInput {
  min-width: 100px;
  width: 100%;
  height: 36px;
  border-width: 1px;
  border-style: solid solid solid solid;
  border-color: rgb(196, 205, 213);
  border-image: initial;
  background: -webkit-linear-gradient(
    top,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 0px;
  font-size: 14px;
  color: rgb(52, 73, 94);
  padding: 0px 6px;
  transition: border-color 200ms ease 0s;
}

.itemPickerMainDiv {
  min-height: 50px;
  max-height: 320px;
}

.marginClass {
  margin-bottom: 12px;
}

.titleWrapper {
  padding: 16px 8px;
  display: flex;
  gap: 8px;
}

.caption {
  color: #212b36;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  /* 142.857% */
}

.selectWrapper {
  padding: 0 20px;
}

.categoryNameWrapper {
  padding: 20px;
}

.headingWrapper {
  padding: 0px 20px 0 20px;
}

.headingText {
  color: #202223;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.GroupConditionContainer {
  position: relative;
}

.rulesContainer {
  padding: 0 8px;
}

.card {
  position: relative;
  display: flex;
  padding: 8px;
  /* flex-direction: column; */
  align-items: center;
  flex-wrap: wrap;

  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 0px solid #e1e3e5;
  background: #f4f6f8;
  width: fit-content;
}
.cardWrappers {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}
.closeButton {
  height: 20px;
  width: 20px;
  text-align: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: -10px;
  color: white;
  background-color: grey;
  border-radius: 50%;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.addCondition {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.groupCondition {
  display: flex;
  padding: 8px 0px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  margin: 0;
}

.Condition {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #fff;
}

.Condition p {
  color: #2c6ecb;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.cancelButtons {
  cursor: pointer;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #babfc3;
  background: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

.saveButtons {
  cursor: pointer;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #babfc3;
  background: #07a9d2;
  color: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

.unselectedButton {
  background: #fff;
  border: 1px solid #c9cccf;
  margin-right: 8px;
  padding: 8px 12px 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 8px;
}

.selectedButton {
  background: #07a9d2;
  border: 1px solid #c9cccf;
  margin-right: 8px;
  padding: 8px 12px 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  color: white;
  margin-bottom: 8px;
}

.customDataTable {
  width: 100%;
  text-align: right;
  border-collapse: collapse;
  /* border: solid 1px  #E1E3E5; */
}

.customDataTable thead th {
  /* min-width: 100px; */
  /* vertical-align: top; */
  white-space: nowrap;
  padding: 8px;
  vertical-align: middle;
}

.customDataTable thead th:first-child {
  text-align: left;
  padding: 8px;
}

.customDataTable tbody tr {
  height: 44px;
}

.customDataTable tbody tr td {
  border-bottom: 1pt solid rgb(244, 245, 247);
}

.customDataTable tbody td {
  padding: 8px;
}

.marginCell {
  min-width: 15px;
  text-align: left;
}

.customDataTable.expandableTable tbody tr {
  height: 60px;
}

.saveBtn {
  float: right;
  margin: 5px;
  padding: 0px;
  cursor: default;
  text-align: center;
  box-sizing: border-box;
  width: 88px;
  height: 36px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #07a9d2;
  color: rgb(255, 255, 255);
  background: -webkit-linear-gradient(top, #07a9d2 0%, #07a9d2 100%);
}

.cancelBtn {
  border: 1px solid rgb(196, 205, 213);
  background: -webkit-linear-gradient(
    top,
    rgb(244, 246, 248) 0%,
    rgb(244, 246, 248) 100%
  );
  color: rgb(33, 43, 54);
  float: right;
  margin: 5px;
  padding: 0px;
  cursor: default;
  text-align: center;
  box-sizing: border-box;
  width: 88px;
  height: 36px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
}

.percentNumberBox {
  width: 70px;
  margin-left: 10px;
  /* margin-top: 20px; */
}
.tabContent {
  width: 200px;
  position: relative;
  top: 0;
  border-right: 1px solid #d9d9d9;
}
.tabHeading {
  padding: 10px 20px;
  background: #f4f6f8;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #637381;
}
.tab {
  padding: 8px 16px 8px 16px;
  background: #fff;
  border: 1px solid #cac6db;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.tabSelected {
  background: #07a9d2;
  padding: 8px 16px 8px 16px;
  border: 0px solid #cac6db;
  line-height: 20px;
  /* font-weight:500; */
  display: flex;
  gap: 8px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
}

.customBadge {
  font-size: 12px; /* smaller font size for a small badge */
  padding: 4px 8px; /* adjust padding for size */
  background-color: #07a9d2; /* example background color */
  color: white; /* text color */
  border-radius: 4px; /* rounded corners */
}

.pageContent {
  padding: 24px;
  margin-top: 0px;
}

.pageContent.productSet {
  padding: 16px !important;
}

.pageContent .tabContent {
  width: 200px;
  height: auto;
  padding-bottom: 20px;
  top: 0;
  border: 1px solid #e1e3e5;
  border-radius: 8px;
}
.tabWrapper {
  position: sticky;
  top: 0px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.pageContent .tabWrapper {
  position: unset;
  gap: 4px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.pageContent .tabHeading {
  border-radius: 8px 8px 0px 0px;
}

.filters {
  flex-grow: 1;
  padding: 24px 24px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pageContent .filters {
  flex-grow: 1;
  padding: 0px;
}

.filterWrapper {
  /* border:1px solid #AEB4B9;
    background-color: #FFF;
    border-radius:8px */
}
.filterWrappers {
  background: #fafbfb;
  border-radius: 8px;
}

.aiTemplateGenerationModal {
  padding: 0px;
}
.subTab {
  padding: 8px;
}
.subTabSelected {
  padding: 8px;
}

.tab {
  /* padding: 8px; */
  cursor: pointer;
  transition: background 0.3s;
}

/* .tabSelected {
    font-weight: bold;
  } */

.subTab {
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.cardWrappers {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(280px, 1fr)
  ); /* Auto-adjust columns with a minimum width of 280px */
  gap: 16px; /* Spacing between grid items */
}

.sectionWrapper {
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px; /* Optional: Add rounded corners */
  box-sizing: border-box;
}
.sectionTitle {
  font-weight: 600;
  padding-bottom: 4px;
  font-size: 14px;
  color: #6d7175;
}

.lineClampAtTwo {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}
