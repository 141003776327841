.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.5rem 0;
  }
  
  .paginationInfo {
    white-space: nowrap;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .paginationItem {
    margin: 0 0.25rem;
  }
  
  .paginationButton {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border: 1px solid #07A9D2;
    background-color: white;
    color: #07A9D2;
    border-radius: 0.25rem;
    min-width: 2rem;
    text-align: center;
    font-size: 0.875rem;
  }
  
  .active .paginationButton {
    background-color: #07A9D2;
    color: white;
  }
  
  .paginationButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .paginationNav {
    font-weight: bold;
  }
  
  .ellipsis {
    font-weight: bold;
  }
  
  .ellipsis:hover {
    background-color: #f8f9fa;
  }
  
  