.titleText{
    font-weight: 700;
    font-size: 24px;
    /* font-family: 'Manrope'; */
    color: #002630;
    /* margin-top:16px; */
}

.topDropdownWrapper {
    margin-left: auto;
    align-items: end;
    display: flex;
    gap: 10px;
}

 
.truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;  
  }
  
 
  .truncated-text:hover {
    overflow: visible;
    white-space: normal;
    text-overflow: clip;
    z-index: 1;  
    position: relative;  
  }

  .mx-260{
     max-height: 260px !important; /*MS-258 discount analysis table scroll and corner issue */
  }
  .discount-main-table{
    margin-top: 20px; /*MS-258 discount analysis table scroll and corner issue */
  }
  .discount-main-table .campaign-list-main-table{/*MS-258 discount analysis table scroll and corner issue */
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    margin-top: 0px;
  }

  .discount-main-table .main-tableRb-body{/*MS-258 discount analysis table scroll and corner issue */
    margin-top: 0px;
  }

  .discount-main-table .main-tableRb-body .tableRb {/*MS-258 discount analysis table scroll and corner issue */
    border: none;
  }

  .table-wrapper {
    overflow-x: auto;
  }
  
  .sticky-column {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;
  }

  .m-width-151{
    min-width: 151px
  }

  .m-width-105{
    min-width: 105px
  }
  .discount-table{
    overflow-y: none !important;
  }
  .discount-label{
    display: flex;
    justify-content: end;
    font-size: 12px;
    font-weight: 600;
}
.avg-discount-table {
  display: flex;
  justify-content: end;
}
.txt-center{
  text-align: center !important;
}
.avg-discount-table{
  max-height: 240px;
  overflow: auto;
}
.fixed-table.avg-discount-fix-table {
  position: sticky;
  top: 0;
  background-color: white;
}

.main-table-row-fixed.avg-discount-header-fix th {
  position: sticky;
  top: 0;
  background-color: white;
}

.fixed-table.category-fix-table {
  position: sticky;
  top: 0;
  background-color: white;
}

.main-table-row-fixed.category-header-fix th {
  position: sticky;
  top: 0;
  background-color: white;
  white-space: nowrap;
}
.border-right{
  border-right: 1px solid #ccc !important
}
   