.secondary-btn {
    display: flex;
    background-image: linear-gradient(0deg, #F0EDFF, #F0EDFF, #F1FCFF);
    border-radius: 24px;
    border: 0px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    color: #4D4184;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    color: white;
}


/* to make react component hover effect null  */
.secondary-btn:first-child:hover,
:not(.btn-check)+.btn:hover {
    color: #4D4184;
}