.dashboard-filter-container {
    padding-right: 20px;


}
.setting-modal-inside-filter-main-content-body{
    height: 53vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 394px;
    padding-right: 21px;
}

.dashboard-filter-container h3 {
    padding-top: 10px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    color: #002630;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

  
}
#firstpol{
    width: 352px !important;
    
}

.select-range-container {
    display: flex;
    justify-content: space-between;
    width: 70%;
}

/* leavle form-check form-check-inline */
.label-text-filter .form-check-label {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
}


.dashboard-filter-container .cam-sum-opt {
    font-weight: 500;
    font-size: 14px;
    /* font-family: 'Inter'; */
    padding-top: 10px;
    color: #4C646A;
}

.targetting-sec-setting .cam-sum-opt2{
    font-weight: 500;
    font-size: 14px;
    /* font-family: 'Inter'; */
    color: #4C646A;
    padding-right: 73px;
}
.targetting-sec-setting .cam-sum-opt-targetting{
    font-weight: 500;
    font-size: 14px;
    /* font-family: 'Inter'; */
    color: #4C646A;
    padding-top: 5px;
}

.dashboard-filter-container .vstack {
    padding-bottom: 15px;
    display: flex;
}

.select-view-container {
    display: flex;
    /* justify-content: space-between; */
    padding-bottom: 3px;
}



.campaign-summary-container .hstack {
    display: flex;
    justify-content: center;

}
.dash-layout-footer-btn {
    width: 430px;
    padding-left: -2px;
    display: flex;
    align-items: flex-end;
    /* justify-content: center; */
    height: 80px;
    /* margin-top: 30px; */
 
}

