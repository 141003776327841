.overview-main-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  
}

.card-container {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
    display: grid;
   grid-template-columns:repeat(6,1fr)       
}