.iframe-style {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 75%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    margin-top: 135px;
    margin-left: 10%; /* 10% margin on left side for every viewport */
    width: 90%;
}

.iframe-report-container {
    max-width: 90%;
    margin: auto;
    margin-top: 200px;
    height: 100%;
}

.iframe-style-open {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 85%;
    position: absolute;
    top: 61px;
    left: 255px;
    right: 0;
    bottom: 0;
    border: none;
}
