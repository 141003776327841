.resetButtonStyles {
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
text-align: right;
color: #4D4184;
cursor: pointer;
/* padding-left: 10px; */
}

.selectAllStyles {
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 16px;
text-transform: capitalize;
color: #63677B;
display: flex;
gap: 1rem;
}

.find-box-container .dynamic-width {
    width: 100%;
    top:0;
    left: 0;
}

.find-box-container .dynamic-width-search-bar {
    width: 94%;
}

.find-box-container #mainInput {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    padding-left: 5px;
}