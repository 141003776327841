.main-rule-setup-container {
  border-radius: 8px;
  background: var(--surface-default, #fff);

  /* shadow-card */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
    0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.main-rule-setup-container .rule-setup-heading {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.main-rule-setup-container .metrics-rule-container {
  border-radius: 8px;
  border: 1px solid var(--border-shadow-border-shadow, #AEB4B9);
  background: var(--surface-subdued, #FAFBFB);
}

.multiselect-search-box {
  top: 60px !important;
}

.automation-rules-desc {
font-family: "Inter";
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0px;
}

.header-icon-container {
  padding: 4px 4px 4px 4px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E2F1EA;
  border-radius: 2px;
}

.rules-individual-container .rules-header {
  padding: 10px 20px 10px 20px;
  border-radius: 8px 8px 0px 0px;
  gap: 10px;
  background-color:#F1F2F3;
}

.rules-individual-container .rules-body {
  background-color: #FAFBFB;
  padding: 20px;
}

.automation-rule-input {
  padding-bottom: 11px !important;
}

.automation-cancel-minus-box {
  margin-left: 5px;
}

.automation-rule-duration-container {
padding: 8px 20px 8px 20px;
border-radius: 8px;
border: 1px 0px 0px 0px;
gap: 10px;
background-color: #EDEEEF;
}

.form-switch-padding-automation {
  padding-left: 1.84em;
}

.box-blank-space {
  background-color: #FAFBFB;
}

.date-btn-height-automations {
  max-height: 36px;
}

.automations-calendar {
  position: absolute;
  top: 53%;
}

.bid-cap-heading {
  width: 130px !important;
  margin-bottom: .1rem;
}

.bid-cap-heading label {
  color: var(--Neutral20, #303346);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Heading 06 Medium */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.schedule-config-label {
  color: var(--Neutral20, #303346);
  text-align: start;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Heading 06 Medium */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.bid-cap-input[disabled] {
  background-color: rgb(243, 241, 241);
}
.exclusion-checkbox-checked {
  background-color: #F2F7FE !important;
}
.exclusion-checkbox-checked:hover {
  background-color: #F2F7FE !important;
}