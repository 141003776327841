.main-container {
    padding: 5px;
    width: 100%;
}

.redioButton {
    /* background: #F9F9F9;
    border: 2px solid #F9F9F9; */
    background: #FAFBFB !important;
    border: 2px solid #C9CCCF !important;
    border-radius: 8px;
    padding: 20px;
}

.redioButton:hover {
    /* background: #F6F4FF; */
    background: #F6F6F7 !important;
    border: 2px solid #999EA4 !important;
}

.redioButton .radio-btn-container {
    padding: 0;
    line-height: 20px;
}

.redioButton.active {
    /* background: #F6F4FF;
    border: 2px solid rgba(77, 65, 132, 0.5); */
    background: #F2F7FE !important;
    border: 2px solid #2C6ECB !important;
}

.redioButton .radio-btn-container .form-check {
    margin-bottom: 0;
    min-height: 20px;
}

.redioButton .form-check-label span:first-child {
    color: #132A30;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
}

.redioButton .form-check-label span:last-child {
    display: inline-block;
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
    margin-bottom: 0;
}

.redioButton-box {
    margin-top: 24px;
}
.redioButton-box .row{
    row-gap: 15px;
}


/* All Font's Setting here  */
.heading-font {
    color: #002630;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* font-family: "Manrope"; */
    height: 32px;
}

.leavle-heading {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #132A30;
}

.leavle-para {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C646A;
}

.campaign-type-box {
    margin-top: 48px;
    margin-bottom: 24px;
}

.sponsored-product-keyword-targeting {
    background: #FFFFFF;
    border: 1px solid #E1DFEB;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
    border-radius: 8px;
    margin-top: 24px;
    padding: 25px 32px 32px 32px;
    width: 100%;
}

.campaign-type-desc {
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #4C646A;
}


.keyword-targeting-tab-box {
    background: #F9F8FD;
    border: 1px solid #F6F4FF;
    border-radius: 8px;
    padding: 20px;
    margin-top: 24px;
    /* height: 802px;     */
     height: 760px; /*MS-258 extra space below table point 11 */
     /* min-width: min-content; */
}


.main-body-table:first-of-type{
    overflow-x: scroll;
    height: 150px;
}

.checkbox-box {
    display: flex;
    column-gap: 22px;
}

/* choose-css */
.type-wrapper {
    padding-top: 25px;
    width: 101%;
}
.type-wrapper .nav-item .nav-link{
    height: 100%;
}

.choose-box-wrap {
    border-radius: 8px;
    border: 1px solid rgba(225, 223, 235, 1);
    box-shadow: 0px 2px 10px 0px rgba(77, 65, 132, 0.12);
    transition: all 0.5s;
    height: 100%;
    position: relative;
}

.choose-box-wrap .tick-box-container {
    position: absolute;
    left: 10px;
    top: 5px;
}

.choose-box-wrap .img-box {
    width: 100%;
    height: 170px;
    background-color: #e7e7e7;
    border-bottom: 1px solid rgba(77, 65, 132, 0.2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.choose-box-wrap .type-box-content {
    padding: 20px 15px;
    transition: all 0.5s;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    min-height: 166px/*MS-258 gap on hover */
}

.choose-box-wrap .type-box-content p {
    margin-top: 10px;
    color: rgba(76, 100, 106, 1);
}

.choose-box-wrap .type-box-content .type-heading {
    display: flex;
    justify-content: space-between;
}

.choose-box-wrap .type-box-content .type-heading span {
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #132A30;
}
/* .main-sp-targetting-table-body-container{
    background-color: green;
} */

.choose-box-wrap:hover .type-box-content {
    background-color: #EDF4FE !important;
     min-height: 166px /*MS-258 gap on hover */
}

/* sponsored-form */
.sponsored-product-keyword-targeting .redioButton-box h6 {
    margin-bottom: 12px;
}

.sponsored-product-keyword-targeting .row-wrapper {
    row-gap: 25px;
    padding-left: 10px;
}

.sponsored-product-keyword-targeting .campaign {
    margin-bottom: 30px;
}

/* .sponsored-product-keyword-targeting form label {
    display: block;
    margin-bottom: 12px;
} */

.sponsored-product-keyword-targeting .row-wrapper label svg {
    margin-left: 12px;
}

.sponsored-product-keyword-targeting .row-wrapper input[type=text] {
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    width: 100%;
    height: 40px;   
    padding: 0px 4px;
}


.sponsored-product-keyword-targeting .select-drop {
    position: relative;
}

.sponsored-product-keyword-targeting  .select-drop::before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    /* background-color: rgba(219, 217, 230, 1); */
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 98;
}

.sponsored-product-keyword-targeting  select {
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    height: 40px;
    z-index: 99;
    position: relative;
    background-color: transparent;
    cursor: pointer;
}

.sponsored-product-keyword-targeting  select:focus {
    box-shadow: none;
    border-color: rgba(77, 65, 132, 0.2);
}

.ad-details {
    background: #F6F4FF;
    border: 2px solid rgba(77, 65, 132, 0.5);
    border-radius: 8px;
    height: 56px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
}

.keyword-targeting {
    margin-top: 60px;
}

/* common-heading */
.common-head {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    display: block;
    /* font-family: 'Manrope'; */
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.common-label {
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
color: #132A30;
margin-bottom: 8px;
display: flex;
}

label.common-label img {
    margin-left: 4px;
}

.campaign-biding {
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
}

.key-wrap .common-head {
    margin-top: 30px;
    margin-bottom: 16px;
}

.key-wrap .common-head svg {
    margin-left: 30px;
}

.key-wrap .left-tabs-example .flex-row {
    column-gap: 18px;
}

.key-wrap .left-tabs-example .primary-btn {
    column-gap: 10px;
}

.tab-wrap {
    margin-left: -1.2%;
    padding-left: 10px;
}

.tab-content>.active {
    display: block;
    width: 99%;
    padding-left: 11px;
} 

.key-wrap .tab-wrap .nav-pills {
    column-gap: 18px;
    row-gap: 10px;
    padding-left: 10px !important;
}

.key-wrap .tab-wrap .nav-pills .nav-item .nav-link {
    column-gap: 10px;
}

.smart-logic .redioButton-box .row {
    row-gap: 20px;
}

.textarea-add-targeting {
    resize: none;
   
}

/* search */
.search-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    width: 100%;
}
.search-product .search-left {
    width: 90%;
}
.search-product .search-left .search-bar-container2{
    display: block;
}


.search-product .right-icon {
    border: 1px solid #BDBDC3;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.match-head {
    font-size: 16px;
    margin: 20px 0 8px 0;
    display: block;
}

.match-head svg {
    margin-left: 8px;
}

.flex-row-cart-page {
    flex-wrap: nowrap;
    margin-bottom: 49px;
    --bs-nav-link-color: #F6F4FF;
    --bs-nav-pills-link-active-bg: #ffff;
    --bs-nav-pills-link-active-color: #F6F4FF;
    --bs-nav-link-hover-color: #F6F4FF;
   
}
.box-active {
    background-color: #F2F7FE;
    border-radius: 1px solid #F6F4FF;
}

.set-filter-modal {
    position: absolute;
    left: 22.5%;
    margin-top: 5%;
}

.filter-active {
    background-color: #07A9D2;
    color: white !important;
}

.white-color-active {
    filter: brightness(500%);
}

.cards-container {
    width: 1120px;
}

.label-hover:hover {
    cursor: pointer;
}

.leavle-hover:hover {
    cursor: pointer;
}

#search-keyword-btn {
    background-color: red;
}

.error-message {
    color: rgb(241, 86, 86);
}

.search-keyword-text {
    width: 150px;
    display: inline-block;
}

.category-search-text {
    width:164px;
    display: inline-block;
}

.product-search-text {
    width:190px;
    display: inline-block;
}

.remove-item-btn:hover {
    cursor: pointer;
}

.ad-details-container {
    padding-left: 21px;
    margin-left: -1.2%;
}

.addetails-asins-table {
    width: 100%;
}
.redioButton.leavle-hover{
    background: #FAFBFB !important;
    border: 2px solid #C9CCCF !important;
}
.redioButton.leavle-hover:hover{
    background: #F6F6F7 !important;
    border: 2px solid #999EA4 !important;
}
.redioButton.leavle-hover.active{
    background: #F2F7FE !important;
    border: 2px solid #2C6ECB !important;
}

#automatic-bid-input-close-match {
    width: auto;
    height: 30px;
    padding:0px 4px
}

#automatic-bid-input-loose-match {
    width: auto;
    height: 30px;
    padding:0px 4px
}

#automatic-bid-input-substitutes {
    width: auto;
    height: 30px;
    padding:0px 4px
}

#automatic-bid-input-complements {
    width: auto;
    height: 30px;
    padding:0px 4px
}

.adgroup-bid {
    font-size: .9rem;
}

.redioButton .radio-btn-containe.leavle .leavle.form-check.form-check-inline input.form-check-input
{
    width: 16px;
    height: 16px;
}

.redioButton .low-width.leavle .leavle.form-check.form-check-inline  label.form-check-label {
    padding: 3px 0 0 0 !important;
}

.redioButton .radio-btn-containe.leavle .leavle.form-check.form-check-inline label.form-check-label
{
    width: calc(100% - 20px);
    padding: 3px 0 0 0;

}




@media only screen and (max-width: 1200px) and (min-width: 1000px)  {
.choose-box-wrap .type-box-content {
    min-height: calc(100vh - 288px);
}
}
@media screen and (max-width: 768px) {
    .type-wrapper .tab-wrap .flex-row-cart-page{
        flex-wrap: wrap;
    }
    .type-wrapper .tab-wrap .flex-row-cart-page .nav-item{
        width: 100%;
    }
    .checkbox-box {
        column-gap: 15px;
    }
}
@media screen and (max-width: 580px) {
    .keyword-targeting-tab-box .main-tableRb-body{
        display: block;
        min-width: auto;
    }
}
@media screen and (max-width: 480px){
    .key-wrap .tab-wrap .nav-pills .nav-item .nav-link{
        column-gap: 5px;
    }
    .search-product .search-left {
        width: 100%;
    }
}
@media screen and (max-width: 380px){
    .search-product{
        flex-wrap: wrap;
        row-gap: 10px;
    }
    .checkbox-box{
        flex-wrap: wrap;
    }
}

