.primary-btn {
    display: flex;
    background-color: #4D4184;
    border-radius: 24px;
    border: 0px;
    height: 32px;
    /* padding: 10px 24px; */
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    font-weight: 500;
    font-size: 12px;
    width: 137px;
}

.primary-btn.active {
    display: flex !important;
    background-color: #4D4184 !important;
    border-radius: 24px !important;
    border: 0px !important;
    height: 32px !important;
    /* padding: 10px 24px; */
    justify-content: center !important;
    align-items: center !important;
    padding: 6px 20px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    width: 137px;
    color: #fff !important;
}

/* // Changing the SVG Color on active and inactive for campagin creation nav links  */

.primary-btn.active svg path {
    fill:white !important;
}

.primary-btn svg path {
    fill:#4D4184 !important;
}

#create-campaign-btn {
    width: 168px;
}
#create-campaign-btn:hover {
    background: #6F62B0;
    box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
    border-radius: 24px;
  
}

#create-campaign-btn-second {
    width: 169px;
}

/* .primary-btn {
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6F62B0;
    --bs-btn-hover-border-color: ##6F62B0;
    --bs-btn-active-bg: #6F62B0;
} */

/* .primary-btn.active {
    --bs-btn-hover-color:#fff;
    --bs-btn-active-color:#fff;
} */

.primary-outline-btn {
    border-color: #4D4184;
    border-radius: 24px;
    color: #4D4184;
}

.primary-btn.nav-link {
    border-color: #4D4184;
    border-radius: 24px;
    border: 1px solid;
    color: #4D4184;
}



.primary-outline-btn:hover {
    background-color: white !important;
    border: 1px solid #4D4184 !important;
    color: #4D4184 !important;
}

#create-portfolio-btn {
    width: 168px;
    cursor: pointer;
    background-color: transparent;
    color: #4D4184;
    border: 1px solid #4D4184;
}
#create-portfolio-btn:hover {
    background: rgba(229, 225, 249, 0.5);
    border: 1px solid #4D4184;
    box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
    border-radius: 24px;
    
}

@media (max-width: 1050px) {
    #create-campaign-btn {
        width: auto;
    }
}