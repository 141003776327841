
.dropdownWrapper {
    margin-left: auto;
    display: flex;
    align-items: end;
    gap: 10px;
    margin-bottom:35px;
}
 

.chartWrapper {
    box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.75);
    width: 30%;
    margin: 13px;
    border-radius: 10px;
    /* border: 1px solid grey; */
}

.chartFooter {
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    color: blue;
    cursor: pointer;
}