.optimizer-search-bar .has-search .form-control {
   border-radius: 8px !important;
}

.optimizer-search-bar .form-control.sm.select {
    border-radius: 0px !important;
    height: 36px !important
}

.optimizer-search-bar {
    height: 26px;
}

.optimizer-search-bar .has-search input {
    height: 38px !important;
}

.optimizer-search-bar .has-search .form-control-icon.sm  {
    padding-top: 6px;
    cursor: pointer;
}


.optimizer-group-by {
    font-size: 12px;
}

.optimizer-btn-icon {
    border-color: #C9CCCF !important;
    color: #5C5F62 !important;
}

/* #mid-performing-filter-btn {
    margin-left: 60px;
}
#spends-decliners-filter-btn {
    margin-left: 50px;
}

#worst-performing-filter-btn {
    margin-left: 60px;
}

#roas-decliners-filter-btn {
    margin-left: 57px;
} */

.quick-filter-btn-width {
    width: 200px;
}

.more-filters-btn {
    width: 100px !important;
    margin-top: .8rem;
}


.all-features-btns {
    border-right: 0.5px solid  #CCCCCC;
    padding-right: 10px;
    height: 40px;
}


