.splibtn-container .btn-group {
    width: 170px;
    height: 32px;
}

.splibtn-container .btn-group>:not(.btn-check:first-child)+.btn {
    width: 32px;
    height: 32px;
    margin-left: -1px;
    justify-content: flex-end;
    margin-left: -1px;
    display: flex;
    flex: 0;
    /* background-color: #DBD9E6; */
    border-left: none;
}

.splibtn-container .btn-outline-secondary {
    --bs-btn-hover-color: #fff;
    /* --bs-btn-hover-bg: #6F62B0; */
    /* background-color: aqua; */
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #FFFFFF;

    --bs-btn-color: #fff;
    --bs-btn-active-bg: #fff;
    ;
    /* --bs-btn-active-bg: #6F62B0; */
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    display: flex;
    flex: 2;
    border-radius: 8px 0px 0px 8px;
}


/* dropdown down arrow box css */
.splibtn-container .btn-group .btn {
    display: flex;
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #4C646A;
    border-radius: 0px 8px 8px 0px;

}