.filterShadow {
    /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
}

.customRangeHeaderButton {
    cursor: pointer;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #BABFC3;
    background: #FFF;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    color: #202223;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    padding:8px;
    gap:8px;
    /* 142.857% */
}

.customRangeDropdownTitle {
    color: #212B36;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}
.quadrantWrapper {
    display: grid;
    grid-template-columns: 2fr 2fr;
    height:480px;
    border-left: 4px dotted #C4CDD5;
    border-bottom: 4px dotted #C4CDD5;
    border-spacing: 8px;
    position: relative;
}
.quadrant {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 16px;
    align-items: center;
    justify-content: center;
    position: relative;
}
.quadrant.high {
    background-color: #B4D4FF;
    border-left:1px solid #000;
}
.quadrant.top {
    background-color: #ABF5D2
}
.quadrant.low {
    background-color: #FFE599;
    border-left:1px solid #000;
    border-bottom:1px solid #000;
}
.quadrant.non {
    background-color: #F9CECC;
    border-bottom:1px solid #000;
}
.headingWrapperLeft {
    display: flex;
    gap:8px;
    align-items: center;
    position: absolute;
    top: 8px;
    left: 8px;
}
.headingWrapperRight {
    display: flex;
    gap:8px;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
}
.heading {
    font-size: 14px;
    font-weight: 600;
}
.headingImage {
    display: flex;
}
.headingImage > img {
    height: 18px;
    width: 18px;
}
.link {
    color: #2C6ECB;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}
.axis {
    position: absolute;
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 16px;
    color:#637381;
    font-weight: 600;
    width:100%;
}
.axis.left {
    left: -420px;
    top: 120px;
    width: 100%;
    transform: rotate(270deg);
    
}
.axis.bottom {
    bottom: 36px;
    width: 100%;
    
}
.subText{
    margin: 0;
    font-size: 14px;
}

.additionalMetrics{
    width: 340px;
}
.totalProduct{
    color: #5C6AC4;
    font-size: 16px;
    padding: 2px 10px;
    border-radius: 4px;
    background-color: #F0F2F5;
    border: 1px solid #BABFC3;
    cursor: pointer;
}
.cardBtnContainer{
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 10px;
}
.cardPrimaryBtn{
border-radius: 24px;
background: #07A9D2;
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
padding:6px 10px;
cursor: pointer;
color: var(--Background-White, #FFF);
text-align: center;
font-feature-settings: 'liga' off, 'clig' off;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
}